import React, { useEffect, useState, useRef } from "react";
import "./warrantLive.css";
import Select from "react-select";
import { get } from "lodash";
import API from "../../../utils/apiCalling";
import { config,COMPLETE_URL } from "../../../utils/apiUrl";
import { printFormattedDate } from "../../../utils/commonConfig";
import { Link, useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Achor from "../../../assets/images/bullet/qlink-achor.png";
import { languageText } from "../../../languageText/liveMatrix";
import {languageText as languageText2}  from "../../../languageText/dwUnderlying"
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar";
import DwTermsSideBars from "../../../components/common/Sidebar/DwTermsSideBar";
import WarrantCalculatorSideBars from "../../../components/common/Sidebar/WarrantCalculatorSideBar";
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar";
import LoaderSpin from "../../../components/common/LoaderSpin";
import { useReactToPrint } from "react-to-print";
import Chart from "../DwUnderlying/classComponent"
const WarrantLiveMatrix = () => {
  const api = new API();
  const navigate = useHistory();

  const [symbolList, setSymbolList] = useState([]);
  const [symbol, setSymbol] = useState()
  const [underlyingric, setUnderlyingric] = useState("");
  const [bid, setbid] = useState("");
  const [theta, settheta] = useState("");
  const [qid, setQid] = useState(Date.now());
  const [uRic, setURic] = useState("");
  const [Live, setLive] = useState({});
  const [lastUpdateDate, setLastUpdateDate] = useState("");
  const [data, setData] = useState("");
  const [day2, setday2] = useState("");
  const [tabel, setTabel1] = useState("block");
  const [tabe2, setTabel2] = useState("none");
  const [sensitivity3, setsenitivity] = useState("");

  const [result, setresult] = useState("");
  const [comprase, setcomprased] = useState("");
  const [rwList, setRwList] = useState([]);
  const [lvList, setLvList] = useState([]);

  const [date1, setDate1] = useState("");

  const [image, setImage] = useState("");
  const [siteLang, setSiteLang] = useState("");
  const [hkssLink, setHkssLink] = useState("#");
  const [showHkSs, setShowHkSs] = useState("none");
  const [ricDataAll, setRicDataAll] = useState([]);
  const [allData, setAllData] = useState();

  const [setUp, setSetUp] = useState(false);
  const [chgStatus, setChgStatus] = useState("0");
  const [localRic, setLocalRic] = useState(
    JSON.parse(window.localStorage.getItem("ric")) === null
      ? []
      : JSON.parse(window.localStorage.getItem("ric"))
  );
  const [ricToShow, setRicToShow] = useState()
  const [ricToShow1, setRicToShow1] = useState()
  const [ricToStore, setRicToStore] = useState()
  const [loadingTable, setLoadingTable] = useState(false)
  const [loadingLV, setLoadingLV] = useState(false)
  const [loadingPContractImage, setLoadingPContractImage] = useState(false)
  const [disclaimerDefination, setDisclaimerDefination] = useState("");
  const [changed, setChanged] = useState(false);
  const [scrollTopVal, setScrollTopVal] = useState(0)

  const [day, setday] = useState("");
  const [key2, setkey2] = useState([]);
  const [hsidata, setHsidata] = useState({});
  const [show, setShow1] = useState("none");
  const [show1, setShow2] = useState("none");
  const [namecode, setName] = useState("");
  const [name2, setName2] = useState("");
  const [hti, setHti] = useState({});
  const [change1, setChange] = useState("");
  const [change2, setChange2] = useState("");
  const [render, setRander] = useState("");
  const [ric, setRic] = useState("");
  const [liveMatrixSelectedName, setLiveMatrixSelectedName] = useState("");
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [date2, setDate2] = useState("");
  const [datekeys, setdatekeys] = useState([]);
  const [livematrixmode0, setlivematrixmode0] = useState([]);
  const [livematrixmode1, setlivematrixmode1] = useState([]);
  const [day4, setday4] = useState([]);
  const [showimage, setShowimage] = useState("none");
  const [lmtabMid, setLmtabMid] = useState("");
  const [lmtabRight, setLmtabRight] = useState("");
  const [todayHead, setTodayHead] = useState("");
  const [hsiHead, setHsiHead] = useState("");
  const [hsiCall, setHsiCall] = useState("none")
  const [hsiPut, setHsiPut] = useState("none")
  const [type, setType] = useState("warrantdata");
  const [hsiHide, setHsiHide] = useState("");
  const [todayTable, setTodayTable] = useState("");
  const [lmTabTbl, setLmTabTbl] = useState("");
  const [fiveDayTable, setFiveDayTable] = useState("none");
  const [rowHSI, setRowHSI] = useState("none");
  const [dailyHsi, setDailyHsi] = useState("");
  const [hisIndex, setHisIndex] = useState("");
  const [rowHSTECH, setRowHSTECH] = useState("none");
  const [rowHHI, setRowHHI] = useState("none");
  const [set50Contract, setSet50Contract] = useState("none");
  const [hsiContract, setHsiContract] = useState("none");
  const [indexTable, setIndexTable] = useState("none");
  const [ssTable, setSsTable] = useState("table-row-group");
  const [indexSM, setIndexSM] = useState("none");
  const [listNo, setListNo] = useState([])
  const [todayUnavailable, setTodayUnavailable] = useState();
  const [statusMsg, setStatusMsg] = useState(0);
  const hsi_point = 25, sp500_point = 2.5, hstech_point = 10, dji_point = 20, ndx_point = 10, set_point = 0.5, hk_point = 0.5;
  const [loadingIndicator, setLoadingIndicator] = useState(true)
  const [htiTime, setHtiTime] = useState("none");
  const [hsiTime, setHsiTime] = useState("block");
  const [tickerVal, setTickerVal] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie("applang");
    if (!siteLanguage) {
      $.cookie('applang', "thai", { expires: 365, path: '/' });
      setSiteLang("thai");
      document.querySelector("body").classList.remove("eng");
    } else {
      if (siteLanguage == "eng") {
        document.querySelector("body").classList.add("eng");
      } else {
        document.querySelector("body").classList.remove("eng");
      }
      setSiteLang(siteLanguage);
    }
  }, []);

  useEffect(() => {
    homeJSONData()
  }, [siteLang]);
  useEffect(() => {
    getComparisonsData(allData);
  }, [allData]);
  useEffect(() => {
    if (siteLang) {
      handleChange();
    }
  }, [ricToShow, siteLang]);

  useEffect(() => {
    validateStickerHeader();
  }, [changed, scrollTopVal])

  //setRicOnLoad
  useEffect(() => {
    if (ricToStore) {
      setLoadingLV(true);
      var temp = localRic;
      if (localRic.includes(ricToStore)) {
        temp = localRic.filter((el) => {
          return el !== ricToStore;
        });
      }
      var lric = [ricToStore, ...temp];
      setLocalRic(lric);
      if (lric) {
        window.localStorage.setItem("ric", JSON.stringify(lric));
      } 
      getWarrantToolsLiveMatrixlastview();
    }
  }, [ricToStore]);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setScrollTopVal(scrollTop);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });


  function validateStickerHeader() {
    var topnavbar = document.querySelector('#topnavbar')
    var scrollTop = scrollTopVal
    if(topnavbar.offsetHeight>60){
      var top = 102;
    }else{
      var top = 52;
    }
    var tickerbar = document.querySelector('#livematrix #ticker');
    var lmtablecontainer = document.querySelector('#livematrix #lmtablecontainer');
    var llimit = tickerbar?.getBoundingClientRect()?.top+window.pageYOffset-top;
    var ulimit = lmtablecontainer?.getBoundingClientRect()?.top+lmtablecontainer?.clientHeight+window.pageYOffset-top;
    var stickerheader = document.querySelector('#stickerheader');
    if(stickerheader){
      stickerheader.style.top = top + 'px';
      if (scrollTop > llimit && scrollTop < ulimit) {
        updateStickerHeader();
        stickerheader.style.display = 'block';
      }else{
        stickerheader.style.display = 'none';
      }
    }
  }


  function updateStickerHeader() {
    let tickerbarx = document.querySelector('#livematrix #ticker');
    let lmtablecontainerx = document.querySelector('#livematrix #lmtablecontainer');
    let stickerheader = document.querySelector('#stickerheader');

    if (tickerbarx && lmtablecontainerx) {
      let lmtablecontainer = lmtablecontainerx.cloneNode(true);
      // Clear the contents of the tbody inside the element with id "today_table"
      let todayTableBody = lmtablecontainer.querySelector("#today_table tbody");
      todayTableBody.innerHTML = '';
      // lmtablecontainer.querySelector("#lm_message_con .lm_message").remove();

      let fiveDayTableRows = lmtablecontainer.querySelectorAll("#livematrix #five_day_table tbody tr");

      if (fiveDayTableRows.length > 0) {
        if (fiveDayTableRows[0].querySelector("td").classList.contains("subtable")) {
          fiveDayTableRows[0].querySelector(".subtable tbody").childNodes.forEach(function (row) {
            if (row.querySelector("th").length !== 1) {
              row.remove();
            }
          });
        } else {
          for (let i = 1; i < fiveDayTableRows.length; i++) {
            fiveDayTableRows[i].remove();
          }
        }

      }

       let stickerHeaderSection = stickerheader.querySelector(".section");
      stickerHeaderSection.innerHTML = '';

      let ricbar = document.querySelector("#livematrix .ricbar").cloneNode(true);

      stickerHeaderSection.appendChild(ricbar);
      stickerHeaderSection.appendChild(lmtablecontainer);
      let lastupdateSpan = stickerHeaderSection.querySelector("#last_update span");
      if( allData?.last_update && (typeof allData?.last_update == "object")){
        lastupdateSpan.innerHTML = "-";
      }else{
        lastupdateSpan.innerHTML = allData?.last_update ? printFormattedDate(allData?.last_update, true, siteLang) : "-"
      }
    }


  }
  //homeJSONData
  const homeJSONData = async () => {
    try {
      let response = await fetch(`${config.homeJSON}?m=dwnews`)
      if (response) {
        let responseData = await response.json();
        if (responseData) {
          var highlight = responseData.dailyhsi;
          var prefix = siteLang == "eng" ? "en" : "thai";
          if (highlight) {
            let todayhighlight = document.getElementById("dailyhsi");
            todayhighlight.querySelector(".news-title").style.display = "";
            todayhighlight.querySelector(".news-title").innerHTML = highlight[prefix + "_title"];
            if (highlight["image"] == 1) {
              let base64String = await fetch(`${COMPLETE_URL}/photo?hsi=${highlight["id"]}`);
              let jsonifiedbase64String = await base64String.text();
              todayhighlight.querySelector(".news-summary").innerHTML = `<a  target="_blank" href=/marketnews/highlight?cat=hsi&id=${highlight.id}><img width='100%' src=${jsonifiedbase64String.toString()}/></a>`;
              todayhighlight.querySelector(".news-summary").classList.add("newsImage");
              todayhighlight.querySelector(".news-title").style.display = "none";
            } else {
              todayhighlight.querySelector(".news-summary").innerHTML = highlight[prefix + "_short_content"];
            }
            if (highlight.id) {
              todayhighlight.querySelector("a").setAttr("href", "/marketnews/highlight?cat=hsi&id=" + highlight.id);
            }
          }
        }

      }
    } catch (error) {
      console.error(error.message)
    }
  }

  const refreshIndex = (arg) => {
    if (arg == "HSI") {
      runLiveHSI();
    }
    if (arg == "HHI") {
      runLiveHHI();
    }
    if (arg == "HSTECH") {
      runLiveHSTECH();
    }
  }

  const handleDisclaimer = () => {
    if (!disclaimerDefination) {
      setDisclaimerDefination("in")
    } else {
      setDisclaimerDefination("")
    }
  }

  useEffect(() => {
    let definition = document.getElementById("defination");
    if (definition) {
      let lmatrix_tbl_f = document.querySelector("#collapse_dwcompare3 #lmatrix_tbl_f");
      if (lmatrix_tbl_f) {
        lmatrix_tbl_f.style.display = "block";
      }
    }
  }, [])


  //Show Hide by classNameORid
  const ShowHideByClassNameOrID = (idClass, showHide) => {
    let result = document.querySelectorAll(idClass);
    for (let i = 0; i < result.length; i++) {
      result[i].style.display = showHide;
    }
  }

  // handleChange on RIC Change
  const handleChange = async () => {
    setLoadingTable(true)
    setLoadingPContractImage(true)
    document.querySelector("#p_contract_image").style.display = "none"
    ShowHideByClassNameOrID(".hsi_item", "none")
    ShowHideByClassNameOrID(".sp500_item", "none")
    ShowHideByClassNameOrID(".dji_item", "none")
    ShowHideByClassNameOrID(".ndx_item", "none")
    ShowHideByClassNameOrID(".hscei_item", "none")
    ShowHideByClassNameOrID(".hstech_item", "none")
    
    let parsedData = await getRicUrl();
    if (parsedData) {
      let { ric_data, symbols } = parsedData;
      if (Array.isArray(ric_data)) {
        ric_data = ric_data[0];
      }
      const liveIndexSymbolData = symbols.map(
        ({ security_code, ric: value }) => ({
          label: `${security_code}`,
          value,
          security_code,
        })
      );
      setSymbolList(liveIndexSymbolData);
      setRicDataAll(ric_data);
      setAllData(parsedData)

      let screenWidth = window.screen.width; // screen width

      if (ric_data.underlying_ticker == "HSI" || ric_data.underlying_ticker == "HSTECH" || ric_data.underlying_ticker == "HSCEI") {
        if (screenWidth <= 767) {
          ShowHideByClassNameOrID(".show-index-sm", "")
        }
      } else {
        ShowHideByClassNameOrID(".show-index-sm", "none")
      }
      if (
        ric_data?.underlying_ticker == "HSI" ||
        ric_data?.underlying_ticker == "S&P500" ||
        ric_data?.underlying_ticker == "S&amp;P500" ||
        ric_data?.underlying_ticker == "HSTECH" ||
        ric_data?.underlying_ticker == "HSCEI" ||
        ric_data?.underlying_ticker == "SET50" ||
        ric_data?.underlying_ticker == "SET50*" ||
        ric_data?.underlying_ticker?.indexOf("DJI") > -1 ||
        ric_data?.underlying_ticker?.indexOf("NDX") > -1
      ) {
        if (
          ric_data?.underlying_ticker == "HSI" ||
          ric_data?.underlying_ticker == "S&P500" ||
          ric_data?.underlying_ticker == "S&amp;P500" ||
          ric_data?.underlying_ticker == "HSTECH" ||
          ric_data?.underlying_ticker == "HSCEI" ||
          ric_data?.underlying_ticker?.indexOf("DJI") > -1 ||
          ric_data?.underlying_ticker?.indexOf("NDX") > -1
        ) {
          ShowHideByClassNameOrID(".lmtab-mid", "none");
          ShowHideByClassNameOrID(".lmtab-right", "none");
        }

        ShowHideByClassNameOrID(".today_head", "none");
        ShowHideByClassNameOrID(".hsi_head", "");
        updateTodayData(parsedData);
      } else if (ric_data?.underlying_ric?.toString().endsWith(".HK")) {
        ShowHideByClassNameOrID(".lmtab-mid", "none");
        ShowHideByClassNameOrID(".lmtab-right", "none");
        ShowHideByClassNameOrID(".today_head", "none");
        ShowHideByClassNameOrID(".hsi_head", "");
        setHkssLink(
          "https://www.warrants.com.hk/en/market/underlying-tech-sg/code/" +
          ric_data?.underlying_ric?.toString().replace(".HK", "")
        );
        setShowHkSs("block");
        ShowHideByClassNameOrID(".show-hk-ss", "block");
        ShowHideByClassNameOrID("#hsi-hide", "none");
        updateTodayData(parsedData);
      } else {
        ShowHideByClassNameOrID("#today_table", "none");
        ShowHideByClassNameOrID(".lmtabtbl", "none");
        ShowHideByClassNameOrID("#five_day_table", "table");
        updateFivedayData(ric_data?.ric);
      }

      if (ric_data?.ric?.indexOf("HSI") > -1) {
        runLiveHSI();
        ShowHideByClassNameOrID("#hsi-hide", "none");
        ShowHideByClassNameOrID(".row_HSI", "");
      } else if (ric_data?.ric?.indexOf("HSTECH") > -1) {
        runLiveHSTECH();
        ShowHideByClassNameOrID("#dailyhsi", "none");
        ShowHideByClassNameOrID(".his-index", "block");
        ShowHideByClassNameOrID(".row_HSTECH", "");
      } else if (ric_data?.ric?.indexOf("HSCEI") > -1) {
        runLiveHHI();
        ShowHideByClassNameOrID("#dailyhsi", "none");
        ShowHideByClassNameOrID(".his-index", "block");
        ShowHideByClassNameOrID(".row_HHI", "");
      } else {
        ShowHideByClassNameOrID("#dailyhsi", "none");
        ShowHideByClassNameOrID(".his-index", "none");
      }

    }
    setLoadingTable(false)
    document.querySelector("#p_contract_image").style.display = ""
    setLoadingPContractImage(false)
    setChanged(true)
  };
  
  const selectChange = (e) => {
    setURic(e.value);
    setRicToShow(e.security_code)
    setRicToStore(e.value)
    navigate.push(`/tools/livematrix/${e.label}`)
  };

  const updateTodayData = async (data) => {
    ShowHideByClassNameOrID("#lm_message_con", "none")
    let {
      ric_data,
      is_compressed,
      symbols,
    } = data;

    if (Array.isArray(data.ric_data)) {
      ric_data = data.ric_data[0];
    } else {
      ric_data = data.ric_data
    }

    setLive(ric_data);
    setUnderlyingric(ric_data.underlying_ticker);
    setbid(ric_data.BID);
    settheta(ric_data.theta);
    setsenitivity(ric_data.sensitivity);
    updateContract(symbols, ric_data.underlying_ric, ric_data.ric, ric_data.underlying_ticker);
    if (listNo?.length > 0 && uRic != symbols[listNo]?.ric) {
      updateTodayData(symbols[listNo]?.ric, data);
    } else {
      updateTodayTable(data, ric_data.ric);
    }
    document.querySelector("#print_content").innerHTML = document.querySelector("#lmtablecontainer").innerHTML
    setcomprased(is_compressed);
    return data;
  };

  const updateTodayTable = (data, ric) => {
    let ric_data;
    ShowHideByClassNameOrID("#lm_message_con", "none")
    if (Array.isArray(data.ric_data)) {
      ric_data = data.ric_data[0];
    } else {
      ric_data = data.ric_data
    }
    var getMessage = data.symbols.find((item) => item["ric"] == ric);
    if (getMessage) {
      var messageData = data.message.find((item) => item["id"] == Number(getMessage["message"]));
      var messageText =
      siteLang == "eng" ? messageData["en_msg"] : messageData["thai_msg"];
      setChgStatus(getMessage["status"]);
      setStatusMsg(getMessage["status_msg"])
      if (getMessage["status"] == "1") {
        ShowHideByClassNameOrID("#lm_message_con", "block")
        document.getElementById("lm_message").textContent = messageText;
        ShowHideByClassNameOrID("#lm_message_con", "block")
        ShowHideByClassNameOrID("#today_table", "none")
        ShowHideByClassNameOrID("#five_day_table", "none")
      }

    }
    if (ric_data.lmuprice == null) {
      ric_data.lmuprice = ric_data.underlying_bid_price;
    }

    var livematrix = [];
    data.livematrix.forEach(function (livematrixData) {
      if (livematrixData.bid > 0.01) {
        livematrix.push(livematrixData);
      }
    });

    var tableElementx = document.getElementById("today_table");
    var tableElement = tableElementx.querySelector("tbody");

    var ubidask = (ric_data.type == 'PUT') ? "underlying_ask" : "underlying_bid";
    updatePage(ric_data)
    if (ric_data.underlying_ticker == "HSI" || ric_data.underlying_ric.toString().endsWith(".HK")) {
      ShowHideByClassNameOrID(".today_head", "none");
      ShowHideByClassNameOrID(".hsi_head", "");
      ShowHideByClassNameOrID(".lmtab-mid", "none");
      ShowHideByClassNameOrID(".lmtab-right", "none");
      ShowHideByClassNameOrID(".lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".today_item", "none");
      ShowHideByClassNameOrID("#lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".index_futures", "none");
      ShowHideByClassNameOrID(".hsi_item", "");
      ShowHideByClassNameOrID(".hstech_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hscei_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hsi_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hsi_item", "");
    }
    if (ric_data.underlying_ticker == "S&P500") {
      ShowHideByClassNameOrID(".today_head", "none");
      ShowHideByClassNameOrID(".hsi_head", "");
      ShowHideByClassNameOrID(".lmtab-mid", "none");
      ShowHideByClassNameOrID(".lmtab-right", "none");
      ShowHideByClassNameOrID(".lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".today_item", "none");
      ShowHideByClassNameOrID("#lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".index_futures", "none");
      ShowHideByClassNameOrID(".sp500_item", "");
      ShowHideByClassNameOrID("#hsi-hide", "none");
    }
    if (ric_data.underlying_ticker.indexOf("DJI") > -1) {
      ShowHideByClassNameOrID(".today_head", "none");
      ShowHideByClassNameOrID(".hsi_head", "");
      ShowHideByClassNameOrID(".lmtab-mid", "none");
      ShowHideByClassNameOrID(".lmtab-right", "none");
      ShowHideByClassNameOrID(".lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".today_item", "none");
      ShowHideByClassNameOrID("#lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".index_futures", "none");
      ShowHideByClassNameOrID(".sp500_item", "block");
      ShowHideByClassNameOrID(".dji_item", "block");
      ShowHideByClassNameOrID(".sp500_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID("#hsi-hide", "none");
    }
    if (ric_data.underlying_ticker.indexOf("NDX") > -1) {
      ShowHideByClassNameOrID(".today_head", "none");
      ShowHideByClassNameOrID(".hsi_head", "");
      ShowHideByClassNameOrID(".lmtab-mid", "none");
      ShowHideByClassNameOrID(".lmtab-right", "none");
      ShowHideByClassNameOrID(".lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".today_item", "none");
      ShowHideByClassNameOrID("#lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".index_futures", "none");
      ShowHideByClassNameOrID(".sp500_item", "block");
      ShowHideByClassNameOrID(".ndx_item", "block");
      ShowHideByClassNameOrID(".sp500_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID("#hsi-hide", "none");
    }
    if (ric_data.underlying_ric.indexOf("HSTECH") > -1 || ric_data.underlying_ticker.indexOf("HSTECH") > -1) {
      ShowHideByClassNameOrID(".today_head", "none");
      ShowHideByClassNameOrID(".hsi_head", "");
      ShowHideByClassNameOrID(".lmtab-mid", "none");
      ShowHideByClassNameOrID(".lmtab-right", "none");
      ShowHideByClassNameOrID(".lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".today_item", "none");
      ShowHideByClassNameOrID("#lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".index_futures", "none");
      ShowHideByClassNameOrID(".hsi_item", "block");
      ShowHideByClassNameOrID(".hstech_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hscei_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hsi_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hstech_item", "block");
    }
    if (ric_data.underlying_ticker.indexOf("HSCEI") > -1) {
      ShowHideByClassNameOrID(".today_head", "none");
      ShowHideByClassNameOrID(".hsi_head", "");
      ShowHideByClassNameOrID(".lmtab-mid", "none");
      ShowHideByClassNameOrID(".lmtab-right", "none");
      ShowHideByClassNameOrID(".lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".today_item", "none");
      ShowHideByClassNameOrID(".index_futures", "none");
      ShowHideByClassNameOrID(".hsi_item", "block");
      ShowHideByClassNameOrID("#lmatrix_tbl_f", "none");
      ShowHideByClassNameOrID(".index_futures", "none");
      ShowHideByClassNameOrID(".hstech_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hscei_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hsi_item.livematrix_disclaimer", "none");
      ShowHideByClassNameOrID(".hscei_item", "block");
    }
    if (livematrix && livematrix.length > 4) {
      var midpoint, tablecontent = "";
      tableElement.innerHTML = ""
      if (ric_data.underlying_ticker == "HSI") {
        var use_point = hsi_point;
        if (ric_data.underlying_ric.toString().endsWith(".HK")) {
          use_point = hk_point;
        }
        var first_point, last_point;
        var compare_table = [];
        if (ric_data.type == "CALL") {
          ShowHideByClassNameOrID(".hsi_call", "");
          first_point = livematrix[0].underlying_bid - livematrix[0].underlying_bid % use_point;
          last_point = livematrix[livematrix.length - 1].underlying_bid - livematrix[livematrix.length - 1].underlying_bid % use_point + use_point;

          for (var i = 0; i < livematrix.length - 1; i += 2) {
            var compare_data = {
              "from": livematrix[i].underlying_bid,
              "to": livematrix[i + 1].underlying_bid,
              "bid": livematrix[i].bid
            };
            compare_table.push(compare_data);
          }
        } else {
          ShowHideByClassNameOrID(".hsi_put", "");

          first_point = livematrix[0].underlying_ask - livematrix[0].underlying_ask % use_point;
          last_point = livematrix[livematrix.length - 1].underlying_ask - livematrix[livematrix.length - 1].underlying_ask % use_point + use_point;
          for (var i = 0; i < livematrix.length - 1; i += 2) {
            var compare_data = {
              "from": livematrix[i].underlying_ask,
              "to": livematrix[i + 1].underlying_ask,
              "bid": livematrix[i].bid
            };
            compare_table.push(compare_data);
          }
        }
        midpoint = findMidPoint(data.livematrix, ric_data.lmuprice, true, false, ric_data.type);
        var classrow = 0;
        for (var i = first_point; i >= last_point; i -= use_point) {
          if (classrow == 0) {
            if (first_point >= Number(midpoint.price) <= last_point) {
              if (i.toString().substring(0, 4) == midpoint.price.toString().substring(0, 4)) {
                classrow = i;
                break;
              } else if (i.toString().substring(0, 3) == midpoint.price.toString().substring(0, 3)) {
                if (Number(i) - Number(midpoint.price) < 25) {
                  var bid = Math.abs(i + 25 - Number(midpoint.price));
                  var bid1 = Math.abs(i - Number(midpoint.price));
                  var bid2 = Math.abs((i - 25) - Number(midpoint.price));

                  if (bid < bid1 && bid < bid2) {
                    classrow = i + 25;
                    break;
                  } else if (bid1 < bid && bid1 < bid2) {
                    classrow = i;
                    break;
                  } else if (bid2 < bid && bid2 < bid1) {
                    classrow = i - 25;
                    break;
                  }
                }
              }
            }
          } else {
            break;
          }
        }
        for (var i = last_point; i < first_point; i += use_point) {
          var bid = _check_hsi_compare_table(compare_table, i);
          var isMidPoint = (i == classrow) ? true : false;
          tablecontent += isMidPoint ? "<tr class='midpoint '><td>" : "<tr><td>";
          tablecontent += i.toFixed(2);
          tablecontent += "</td><td class='right-b01'>";
          tablecontent += bid;
          tablecontent += "</td></tr>";
        }
        tableElement.innerHTML = tablecontent;

      } else if (ric_data.underlying_ticker == "S&P500" || ric_data.underlying_ticker.indexOf("DJI") > -1 || ric_data.underlying_ticker.indexOf("NDX") > -1) {
        var first_point, last_point;
        var compare_table = [];
        var interval = sp500_point;
        if (ric_data.underlying_ticker.indexOf("DJI") > -1)
          interval = dji_point;
        if (ric_data.underlying_ticker.indexOf("NDX") > -1)
          interval = ndx_point;
        if (ric_data.type == "CALL") {
          ShowHideByClassNameOrID(".hsi_call", "");
        } else {
          ShowHideByClassNameOrID(".hsi_put", "");
        }
        first_point = Number(livematrix[0].underlying_bid) - Number(livematrix[0].underlying_bid) % interval;
        last_point = Number(livematrix[livematrix.length - 1].underlying_bid) - Number(livematrix[livematrix.length - 1].underlying_bid) % interval + interval;

        for (var i = 0; i < livematrix.length - 1; i += 2) {
          var compare_data = {
            "from": Number(livematrix[i].underlying_bid),
            "to": Number(livematrix[i + 1].underlying_bid),
            "bid": Number(livematrix[i].bid)
          };
          compare_table.push(compare_data);
        }
        midpoint = ric_data?.underlying_bid_price?.replace(",", "");
        midpoint = Number((Number(midpoint) / 5).toFixed(0)) * 5;
        for (var i = last_point; i < first_point; i += interval) {
          var bid = _check_hsi_compare_table(compare_table, i);
          var isMidPoint = (i.toFixed(0) == midpoint.toFixed(0)) ? true : false;
          //                        var isMidPoint = false;
          tablecontent += isMidPoint ? "<tr class='midpoint '><td>" : "<tr><td>";
          if (ric_data.underlying_ticker == "S&P500")
            tablecontent += i.toFixed(1)
          else
            tablecontent += i.toFixed(0);
          tablecontent += "</td><td class='right-b01'>";
          tablecontent += bid.toFixed(2);
          tablecontent += "</td></tr>";
        }
        tableElement.innerHTML = tablecontent;

      } else if (ric_data.underlying_ticker.indexOf("HSTECH") > -1 || ric_data.underlying_ticker.indexOf("HSTECH") > -1 || ric_data.underlying_ticker.indexOf("HSCEI") > -1) {
        var first_point, last_point;
        var compare_table = [];
        if (ric_data.type == "CALL") {
          ShowHideByClassNameOrID(".hsi_call", "");
          first_point = livematrix[0].underlying_bid - livematrix[0].underlying_bid % hstech_point;
          last_point = livematrix[livematrix.length - 1].underlying_bid - livematrix[livematrix.length - 1].underlying_bid % hstech_point + hstech_point;
          for (var i = 0; i + 1 < livematrix.length; i += 2) {
            var compare_data = {
              "from": livematrix[i].underlying_bid,
              "to": livematrix[i + 1].underlying_bid,
              "bid": livematrix[i].bid
            };
            compare_table.push(compare_data);
          }
        } else {
          ShowHideByClassNameOrID(".hsi_put", "");
          first_point = livematrix[0].underlying_ask - livematrix[0].underlying_ask % hstech_point;
          last_point = livematrix[livematrix.length - 1].underlying_ask - livematrix[livematrix.length - 1].underlying_ask % hstech_point + hstech_point;
          for (var i = 0; i < livematrix.length - 1; i += 2) {
            var compare_data = {
              "from": livematrix[i].underlying_ask,
              "to": livematrix[i + 1].underlying_ask,
              "bid": livematrix[i].bid
            };
            compare_table.push(compare_data);
          }
        }
        midpoint = findMidPoint(data.livematrix, ric_data.lmuprice, true, false, ric_data.type);
        var classrow = 0;
        for (var i = first_point; i >= last_point; i -= hstech_point) {
          if (classrow == 0) {
            if (first_point >= Number(midpoint.price) <= last_point) {
              if (i.toString().substring(0, 4) == midpoint.price.toString().substring(0, 4)) {
                classrow = i;
                break;
              } else if (i.toString().substring(0, 3) == midpoint.price.toString().substring(0, 3)) {
                if (Number(i) - Number(midpoint.price) < 25) {
                  var bid = Math.abs(i + 25 - Number(midpoint.price));
                  var bid1 = Math.abs(i - Number(midpoint.price));
                  var bid2 = Math.abs((i - 25) - Number(midpoint.price));
                  if (bid < bid1 && bid < bid2) {
                    classrow = i + 25;
                    break;
                  } else if (bid1 < bid && bid1 < bid2) {
                    classrow = i;
                    break;
                  } else if (bid2 < bid && bid2 < bid1) {
                    classrow = i - 25;
                    break;
                  }
                }
              }
            }
          } else {
            break;
          }
        }
        for (var i = last_point; i < first_point; i += hstech_point) {
          var bid = _check_hsi_compare_table(compare_table, i);
          var isMidPoint = (i == classrow) ? true : false;
          tablecontent += isMidPoint ? "<tr class='midpoint '><td>" : "<tr><td>";
          tablecontent += i.toFixed(2);
          tablecontent += "</td><td class='right-b01'>";
          tablecontent += bid;
          tablecontent += "</td></tr>";
        }
        tableElement.innerHTML = tablecontent;

      } else if (ric_data.underlying_ticker.indexOf("SET") > -1) {
        var first_point, last_point;
        var compare_table = [];
        if (ric_data.type == "CALL") {
          ShowHideByClassNameOrID(".hsi_call", "");
          first_point = livematrix[0].underlying_bid - livematrix[0].underlying_bid % set_point;
          last_point = livematrix[livematrix.length - 1].underlying_bid - livematrix[livematrix.length - 1].underlying_bid % set_point + set_point;
          for (var i = 0; i + 1 < livematrix.length; i += 2) {
            var compare_data = {
              "from": livematrix[i].underlying_bid,
              "to": livematrix[i + 1].underlying_bid,
              "bid": livematrix[i].bid
            };
            compare_table.push(compare_data);
          }
        } else {
          ShowHideByClassNameOrID(".hsi_put", "");
          first_point = livematrix[0].underlying_ask - livematrix[0].underlying_ask % set_point;
          last_point = livematrix[livematrix.length - 1].underlying_ask - livematrix[livematrix.length - 1].underlying_ask % set_point + set_point;
          for (var i = 0; i < livematrix.length - 1; i += 2) {
            var compare_data = {
              "from": livematrix[i].underlying_ask,
              "to": livematrix[i + 1].underlying_ask,
              "bid": livematrix[i].bid
            };
            compare_table.push(compare_data);
          }
        }
        midpoint = findMidPoint(data.livematrix, ric_data.lmuprice, true, false, ric_data.type);
        var classrow = 0;
        for (var i = first_point; i >= last_point; i -= set_point) {
          if (classrow == 0) {
            if (first_point >= Number(midpoint.price) <= last_point) {
              if (i.toString().substring(0, 4) == midpoint.price.toString().substring(0, 4)) {
                classrow = i;
                break;
              } else if (i.toString().substring(0, 3) == midpoint.price.toString().substring(0, 3)) {
                if (Number(i) - Number(midpoint.price) < 25) {
                  var bid = Math.abs(i + 25 - Number(midpoint.price));
                  var bid1 = Math.abs(i - Number(midpoint.price));
                  var bid2 = Math.abs((i - 25) - Number(midpoint.price));
                  if (bid < bid1 && bid < bid2) {
                    classrow = i + 25;
                    break;
                  } else if (bid1 < bid && bid1 < bid2) {
                    classrow = i;
                    break;
                  } else if (bid2 < bid && bid2 < bid1) {
                    classrow = i - 25;
                    break;
                  }
                }
              }
            }
          } else {
            break;
          }
        }
        for (var i = last_point; i < first_point; i += set_point) {
          var bid = _check_hsi_compare_table(compare_table, i);
          var isMidPoint = (i == classrow) ? true : false;
          tablecontent += isMidPoint ? "<tr class='midpoint '><td>" : "<tr><td>";
          tablecontent += i.toFixed(2);
          tablecontent += "</td><td class='right-b01'>";
          tablecontent += bid;
          tablecontent += "</td></tr>";
        }
        tableElement.innerHTML = tablecontent;
        // tableElement.html(tablecontent,);
      } else if (ric_data.underlying_ric.toString().endsWith(".HK")) {
        if (ric_data.type == "CALL") {
          ShowHideByClassNameOrID(".hsi_call", "");
          midpoint = findMidPoint(data.livematrix, ric_data.lmuprice, false, false, ric_data.type);
          var livematrixindex = 0;
          var livematrixlimit = 9;
          livematrixindex = midpoint.index;
          if (livematrixindex == 0) {
            livematrixindex = Math.ceil(livematrix.length / 2);
          }
          //                        System.out.println(livematrix);
          for (var i = 0; i < livematrix.length; i++) {

            //if (i > livematrixindex - livematrixlimit && i < livematrixindex + livematrixlimit) {
            var livematrixdata = livematrix[i];
            var isMidPoint = (livematrixdata[ubidask] == midpoint.price) ? true : false;


            tablecontent += isMidPoint ? "<tr class='midpoint '><td>" : "<tr><td>";
            tablecontent += Number(livematrixdata.underlying_bid).toFixed(2);
            tablecontent += "</td><td class='right-b01'>";
            tablecontent += Number(livematrixdata.bid).toFixed(2);
            tablecontent += "</td></tr>";

            //}
          }
          tableElement.innerHTML = tablecontent;
        } else {
          ShowHideByClassNameOrID(".hsi_put", "");
          midpoint = findMidPoint(data.livematrix, ric_data.lmuprice, false, false, ric_data.type);
          var livematrixindex = 0;
          var livematrixlimit = 9;
          livematrixindex = midpoint.index;
          if (livematrixindex == 0) {
            livematrixindex = Math.ceil(livematrix.length / 2);
          }
          for (var i = 0; i < livematrix.length; i++) {
            //if (i > livematrixindex - livematrixlimit && i < livematrixindex + livematrixlimit) {
            var livematrixdata = livematrix[i];
            var isMidPoint = (livematrixdata[ubidask] == midpoint.price) ? true : false;
            tablecontent += isMidPoint ? "<tr class='midpoint '><td>" : "<tr><td>";
            tablecontent += Number(livematrixdata.underlying_ask).toFixed(2)
            tablecontent += "</td><td class='right-b01'>";
            tablecontent += Number(livematrixdata.bid).toFixed(2);
            tablecontent += "</td></tr>";
            //}
          }
          tableElement.innerHTML = tablecontent;

          // tableElement.html(tablecontent);
        }
      } else if (livematrix[0].is_compressed) {
        midpoint = findMidPoint(data.livematrix, ric_data.lmuprice, true, false, ric_data.type);
        var hrow, trow;
        var scount = 1, lcount = 1;
        for (var i = 0; i < livematrix.length; i++) {
          hrow = livematrix[i];
          trow = livematrix[i + 1]

          if (!isNaN(hrow[ubidask])) {
            hrow[ubidask] = Number(hrow[ubidask]);
          }

          if (hrow.head_tail_id == trow.head_tail_id) {
            var classrow = "";
            if (hrow[ubidask] == midpoint.price) {
              classrow += "midpoint";
            } else if (hrow[ubidask] < midpoint.price) {
              classrow += "srow srow" + scount;
              scount++;
            } else if (hrow[ubidask] > midpoint.price) {
              classrow += "lrow lrow" + lcount;
              lcount++;
            }
            tablecontent += "<tr class='" + classrow + "'><td>";
            //tablecontent += Number(hrow[ubidask]).toFixed(2) + " - " + Number(trow[ubidask]).toFixed(2)
            tablecontent += Number(trow["underlying_bid"]).toFixed(2) + " - " + Number(hrow["underlying_bid"]).toFixed(2);
            tablecontent += "</td><td class='right-b01'>";
            tablecontent += Number(hrow.bid).toFixed(2);
            tablecontent += "</td><td>";
            tablecontent += Number(hrow.ask).toFixed(2);
            tablecontent += "</td><td>";
            tablecontent += Number(trow["underlying_ask"]).toFixed(2) + " - " + Number(hrow["underlying_ask"]).toFixed(2);
            tablecontent += "</td></tr>";
            i++;
          }
        }
        tableElement.innerHTML = tablecontent;

        // tableElement.html(tablecontent);
        if (ric_data.BID == 0.04) {
          tableElement.querySelector(".srow").style.display = "none";
          //                        tableElement.find(".lrow").hide();
          //                        for (var i = lcount; i >= lcount - 8; i--) {
          //                            tableElement.find(".lrow" + i).show();
          //                        }
        }
        //                    else {
        //                        tableElement.find(".srow").hide();
        //                        tableElement.find(".lrow").hide();
        //                        for (var i = lcount; i >= lcount - 8; i--) {
        //                            tableElement.find(".lrow" + i).show();
        //                        }
        //                        for (var i = 1; i <= 8; i++) {
        //                            tableElement.find(".srow" + i).show();
        //                        }
        //                    }
      } else {
        midpoint = findMidPoint(data.livematrix, ric_data.lmuprice, false, false, ric_data.type); var livematrixindex = 0;
        var livematrixlimit = 9;
        livematrixindex = midpoint.index;
        if (livematrixindex == 0) {
          livematrixindex = Math.ceil(livematrix.length / 2);
        }
        for (var i = 0; i < livematrix.length; i++) {
          //if (i > livematrixindex - livematrixlimit && i < livematrixindex + livematrixlimit) {
          var livematrixdata = livematrix[i];
          var isMidPoint = (livematrixdata[ubidask] == midpoint.price) ? true : false;
          tablecontent += isMidPoint ? "<tr class='midpoint '><td>" : "<tr><td>";
          tablecontent += Number(livematrixdata.underlying_bid).toFixed(2);
          tablecontent += "</td><td class='right-b01'>";
          tablecontent += Number(livematrixdata.bid).toFixed(2);
          tablecontent += "</td><td>";
          tablecontent += Number(livematrixdata.ask).toFixed(2)
          tablecontent += "</td><td>";
          tablecontent += Number(livematrixdata.underlying_ask).toFixed(2)
          tablecontent += "</td></tr>";
          //}
        }
        tableElement.innerHTML = tablecontent;

      }
      var rows = tableElement.querySelectorAll('tr:not([style*="display:none"])');
      for (var i = 0; i < rows.length; i++) {
        if (i % 2 === 0) {
          rows[i].classList.add('bgcolor-08');
        }
      }
      // tableElement.find('tr:visible').filter(':even').addClass('bgcolor-08');
    } else if (data.livematrix && livematrix.length <= 4) {
      if (ric_data.issuer == "MACQ") {
        if (chgStatus == "0") {
          ShowHideByClassNameOrID("#text-content .table_livematrix_today_unavailable tr", "");
          tableElement.innerHTML = document.querySelector("#text-content .table_livematrix_today_unavailable tr").innerHTML
        } else {
          ShowHideByClassNameOrID("#text-content .table_livematrix_today_unavailable tr", "none");

        }
      } else {
        if (chgStatus == "0") {
          ShowHideByClassNameOrID("#text-content .tr_livematrix_none_mq tr", "");
          tableElement.innerHTML = document.querySelector("#text-content .table_livematrix_today_unavailable tr").innerHTML
        } else {
          ShowHideByClassNameOrID("#text-content .tr_livematrix_none_mq tr", "none");
        }
      }
      if(ric_data.list_date){
        var date = new Date()
        var listDate = new Date(ric_data.list_date)
        if(date.getFullYear()==listDate.getFullYear() &&date.getMonth()==listDate.getMonth() &&date.getDate()==listDate.getDate()){
          var hours = getLocalTime(7).getHours();
          if(hours<9){
            tableElement.innerHTML = document.querySelector("#text-content .table_livematrix_today_new_unavailable tr").innerHTML
          }
        }
      }
    } else {
      if (ric_data.issuer == "MACQ") {
        if (chgStatus == "0") {
          ShowHideByClassNameOrID("#text-content .tr_livematrix_today_empty tr", "");
          tableElement.innerHTML = document.querySelector("#text-content .tr_livematrix_today_empty tr").innerHTML

        } else {
          ShowHideByClassNameOrID("#text-content .tr_livematrix_today_empty tr", "none");
        }
      } else {

        if (chgStatus == "0") {
          ShowHideByClassNameOrID("#text-content .tr_livematrix_none_mq tr", "");
          tableElement.innerHTML = document.querySelector("#text-content .tr_livematrix_none_mq tr").innerHTML

        } else {
          ShowHideByClassNameOrID("#text-content .tr_livematrix_none_mq tr", "none");
        }
      }
      if(ric_data.list_date){
        var date = new Date()
        var listDate = new Date(ric_data.list_date)
        if(date.getFullYear()==listDate.getFullYear() &&date.getMonth()==listDate.getMonth() &&date.getDate()==listDate.getDate()){
          var hours = getLocalTime(7).getHours();
          if(hours<9){
            tableElement.innerHTML = document.querySelector("#text-content .table_livematrix_today_new_unavailable tr").innerHTML
          }
        }
      }
    }
  }
  
  const getLocalTime = (i)=> {
    if (typeof i !== 'number') return;
    var d = new Date();
    var len = d.getTime();
    var offset = d.getTimezoneOffset() * 60000;
    var utcTime = len + offset;
    return new Date(utcTime + 3600000 * i);
  }

  const updateFivedayData = async (uRic) => {
    if (uRic == "" || uRic == undefined || uRic == null) {
      const response11 = await fetch(
        `${config.LiveMatrix}?ric=&mode=1&qid=${qid}`
      );
      const data11 = await response11.json();
      const liveIndexSymbolData = data11?.symbols.map(
        ({ security_code, ric: value }) => ({
          label: `${security_code}`,
          value,
          security_code,
        })
      );
      setSymbolList(liveIndexSymbolData);
      setURic(data11?.symbols[0]?.ric);
    } else {
      const response1 = await fetch(
        `${config.LiveMatrix}?ric=${uRic}&mode=1&qid=${qid}`
      );
      ShowHideByClassNameOrID("#lm_message_con", "none");
      const data1 = await response1.json();
      let {
        last_update,
        ric_data,
        symbols,
      } = data1;
      if (data1 != "" || data1 != [] || data1 != undefined || data1 !== null) {
        if (!setUp) {
          if (Array.isArray(data1.ric_data)) {
            ric_data = data1.ric_data[0];
          } else {
            ric_data = data1.ric_data
          }

          const liveIndexSymbolData1 = symbols.map(
            ({ security_code, ric: value }) => ({
              label: `${security_code}`,
              value,
              security_code,
            })
          );
          setSymbolList(liveIndexSymbolData1);
          setUnderlyingric(ric_data?.underlying_ticker);
          updateContract(symbols, ric_data.underlying_ric, ric_data.ric, ric_data.underlying_ticker);
          setAllData(data1);
          updateStickerHeader()
          setLastUpdateDate(printFormattedDate(last_update, true, siteLang));
          let stickerheader = document.querySelector('#stickerheader');
          let stickerHeaderSection = stickerheader.querySelector(".section");
          let lastupdateSpan = stickerHeaderSection.querySelector("#last_update span");
          if(lastupdateSpan){
            lastupdateSpan.innerHTML = last_update ? last_update: "-";
          }
          setSetUp(true);
        }
        updateFivedayTable(data1, uRic);
        document.querySelector("#print_content").innerHTML = document.querySelector("#lmtablecontainer").innerHTML
      }

    }
  };

  useEffect(()=>{
    let stickerheader = document.getElementById("stickerheader");
    if(stickerheader){
      setTimeout(()=>{
      let todayRefreshButton = stickerheader.querySelector("#today_table .icon-refresh");
      let fiveDayRefreshButton = stickerheader.querySelector("#five_day_table .icon-refresh");
      let printImages = stickerheader.querySelectorAll(".printimg");
        if(todayRefreshButton || fiveDayRefreshButton ||printImages){
          addEventListeners();
        }
      },2000)
      return ()=> {
        removeEventListeners();
      }
    }
  },[])
  
  const removeEventListeners = ()=>{
    // Add a click event listener to the element with class "icon-refresh" inside "today_table"
    let stickerheader = document.getElementById("stickerheader");
    if(stickerheader){
      let todayRefreshButton = stickerheader.querySelector("#today_table .icon-refresh");
      if(todayRefreshButton){
        todayRefreshButton.removeEventListener('click', function () {
            updateTodayData(allData);
        });
      }
  
      // Add a click event listener to the element with class "icon-refresh" inside "five_day_table"
      let fiveDayRefreshButton = stickerheader.querySelector("#five_day_table .icon-refresh");
  
      if(fiveDayRefreshButton){
        fiveDayRefreshButton.removeEventListener('click', function () {
          updateFivedayData(allData);
        });
      }
  
      // Add a click event listener to elements with class "printimg"
      let printImages = stickerheader.querySelectorAll(".printimg");
  
      if(printImages){
        printImages.forEach(function (printImage) {
          if(printImage){
            printImage.removeEventListener('click', function (e) {
              // window.print()             
            });
          }
        });
      }
    }
  }


  const addEventListeners = ()=>{
    // Add a click event listener to the element with class "icon-refresh" inside "today_table"
    let stickerheader = document.getElementById("stickerheader");
    if(stickerheader){
      let todayRefreshButton = stickerheader.querySelector("#today_table .icon-refresh");

      if(todayRefreshButton){
        todayRefreshButton.addEventListener('click', function () {
            updateTodayData(allData);
        });
      }
  
      // Add a click event listener to the element with class "icon-refresh" inside "five_day_table"
      let fiveDayRefreshButton = stickerheader.querySelector("#five_day_table .icon-refresh");
      if(fiveDayRefreshButton){
        fiveDayRefreshButton.addEventListener('click', function () {
          updateFivedayData(allData);
        });
      }
  
      // Add a click event listener to elements with class "printimg"
      let printImages = stickerheader.querySelectorAll(".printimg");
  
      if(printImages){
        printImages.forEach(function (printImage) {
          if(printImage){
            printImage.addEventListener('click', function (e) {
              window.print()             
            });
          }
        });
      }
    }
  }

  const updateContract = (symbols, underlying_ric, ric, ticker) => {
    ShowHideByClassNameOrID("#p-contract", "none");
    ShowHideByClassNameOrID("#p-contract.hsi-contract", "none");
    ShowHideByClassNameOrID("#p-contract.set50-contract", "none");
    if (symbols?.length > 0) {
      for (var i = 0; i < symbols?.length; i++) {
        var futurename = symbols[i]["future_dsply_name"];
        if (ric === symbols[i]["ric"]) {
          if (futurename != "-") {
            if (underlying_ric?.indexOf("S50") > -1) {
              document.querySelector("#p-contract.set50-contract span").textContent =
                futurename;
              ShowHideByClassNameOrID("#p-contract.set50-contract", "");
            } else if (underlying_ric?.indexOf("HSI") > -1) {
              document.querySelector("#p-contract.hsi-contract span").textContent =
                futurename;
              ShowHideByClassNameOrID("#p-contract.hsi-contract", "block");
            } else if (underlying_ric?.indexOf("ESc") > -1) {
              document.querySelector("#p-contract.hsi-contract span").textContent =
                futurename;
              ShowHideByClassNameOrID("#p-contract.hsi-contract", "block");
            } else if (
              underlying_ric?.indexOf("HSTECH") > -1 ||
              ticker?.indexOf("HSTECH") > -1 ||
              ticker?.indexOf("HSCEI") > -1
            ) {
              document.querySelector("#p-contract.hsi-contract span").textContent =
                futurename;
              ShowHideByClassNameOrID("#p-contract.hsi-contract", "block");
            } else if (ticker?.indexOf("NDX") > -1) {
              document.querySelector("#p-contract.hsi-contract span").textContent =
                futurename;
              ShowHideByClassNameOrID("#p-contract.hsi-contract", "block");
            } else if (ticker?.indexOf("DJI") > -1) {
              document.querySelector("#p-contract.hsi-contract span").textContent =
                futurename;
              ShowHideByClassNameOrID("#p-contract.hsi-contract", "block");
              break;
            }
          }
        }
      }
    }
  };


  // update Five Day Table
  const updateFivedayTable = (data1, uRic) => {
    let ric_data;
    if (Array.isArray(data1.ric_data)) {
      ric_data = data1.ric_data[0];
    } else {
      ric_data = data1.ric_data
    }
    var midpoint, tablecontent = "";
    var ubidask = ric_data?.type == "PUT" ? "underlying_ask" : "underlying_bid";

    var getMessage = data1?.symbols?.find((item) => item["ric"] == uRic);
    if (getMessage) {

      var messageData = data1?.message?.find(
        (item) => item["id"] == Number(getMessage["message"])
      );

      var messageText =
        siteLang == "eng" ? messageData["en_msg"] : messageData["thai_msg"];
      setChgStatus(getMessage["status"]);
      setStatusMsg(getMessage["status_msg"])
      if (getMessage["status"] == "1") {
        ShowHideByClassNameOrID("#lm_message_con", "");
        document.getElementById("lm_message").textContent = messageText;
        ShowHideByClassNameOrID("#today_table", "none");
        ShowHideByClassNameOrID("#five_day_table", "none");
      }
    }
    if (ric_data?.type == "PUT") {
      document.querySelector("#five_day_table_tmpl  tr > th").textContent =document.querySelector("#text-content > .label_underlyingoffer").textContent;
    }
    var tableElement = document.querySelector("#five_day_table tbody");
    tableElement.textContent = "";

    var hide_matrix, hide_date = "", ftnm;
    data1?.symbols.forEach((e) => {
      if (e.ric == uRic) {
        hide_matrix = e.hide_matrix;
        hide_date = e.hide_date;
        ftnm = e.future_dsply_name;
      }
    });

    if (hide_matrix != "1") {
      hide_date = "";
    }
    updatePage(ric_data);
    if (ric_data?.BID <= 0.01) {
      if (ric_data?.issuer == "MACQ") {
        if (getMessage["status"] == "0") {
          ShowHideByClassNameOrID("#text-content .table_livematrix_fiveday_unavailable tr", "");
          tableElement.innerHTML = document.querySelector(
            "#text-content .table_livematrix_fiveday_unavailable tr"
          ).innerHTML;
        } else {
          ShowHideByClassNameOrID("#text-content .table_livematrix_fiveday_unavailable tr", "none");
        }
      } else {
        if (getMessage["status"] == "0") {
          ShowHideByClassNameOrID("#text-content .table_livematrix_none_mq tr", "");
          tableElement.innerHTML = document.querySelector(
            "#text-content .table_livematrix_none_mq tr"
          ).innerHTML;
        } else {
          ShowHideByClassNameOrID("#text-content .table_livematrix_none_mq tr", "none");
        }
      }
      return;
    }
    if (ric_data.underlying_ticker.indexOf("SET") > -1) {
      var livematrix = [];
      var fdates = data1?.fdates;
      var dates = data1?.dates;
      var max = 0;
      var min = 0;
      var lmdata = [];
      var count = [];
      for (var i = 0; i < dates.length; i++) {
        var length = data1[dates[i]].length;
        var first_bid = Number(data1[dates[i]][0][ubidask]);
        var last_bid = Number(data1[dates[i]][length - 1][ubidask]);
        var lmax, lmin;
        if (first_bid > last_bid) {
          lmax = first_bid;
          lmin = last_bid;
        } else {
          lmax = last_bid;
          lmin = first_bid;
        }
        lmax = (Math.ceil(Number(lmax) * 2) - 1) / 2;
        lmin = Math.ceil(Number(lmin) * 2) / 2;
        if (i == 0) {
          max = lmax;
          min = lmin;
        } else {
          max = lmax > max ? max : lmax;
          min = lmin < min ? min : lmin;
        }
        count.push(0);
        lmdata.push({});
      }
      for (var i = 0; i < dates.length; i++) {
        var n2 = Number(count[i]);
        for (var num = max; num >= min; num -= 0.5) {
          var datebid;
          for (var j = n2; j < data1[dates[i]].length; j++) {
            var datedata = data1[dates[i]][j];
            datebid = datedata.bid;
            if (Number(datedata[ubidask]) <= num) {
              count[i] = j;
              break;
            }
          }
          lmdata[i][num] = datebid;
        }
      }
      for (var num = max; num >= min; num -= 0.5) {
        var lmrow = {};
        var datarow = {};
        for (var i = 0; i < dates.length; i++) {
          datarow[fdates[i]] = lmdata[i][num];
        }
        lmrow[num.toFixed(2)] = datarow;
        livematrix.push(lmrow);
      }
      if (livematrix?.length == 0) {
        if (ric_data?.issuer == "MACQ") {
          if (getMessage["status"] == "0") {
            ShowHideByClassNameOrID("#text-content .table_livematrix_fiveday_empty tr", "");
            tableElement.innerHTML = document.querySelector(
              "#text-content .table_livematrix_fiveday_empty tr"
            ).innerHTML;
          } else {
            ShowHideByClassNameOrID("#text-content .table_livematrix_fiveday_empty tr", "none");
          }
        } else {
          if (getMessage["status"] == "0") {
            ShowHideByClassNameOrID("#text-content .table_livematrix_none_mq tr", "");
            tableElement.innerHTML = document.querySelector(
              "#text-content .table_livematrix_none_mq tr"
            ).innerHTML
          } else {
            ShowHideByClassNameOrID("#text-content .table_livematrix_none_mq tr", "none");
          }
        }
        return;
      }
      var date_keys = fdates;
      var total_keys = date_keys.length;
      var holidays = data1?.holidays;
      midpoint = findMidPoint(
        livematrix,
        ric_data?.lmuprice,
        data1.is_compressed,
        true,
        ric_data?.type
      );
      var livematrixlimit = 9;
      var th_tmpl = document.querySelector("#five_day_table_tmpl tr");
      var colsHTML = "";
      for (var i = total_keys - 1; i >= 0; i--) {
        colsHTML +=
          (i < 2 && total_keys > 3 ? "<th class='hide-xs-portrait'>" : "<th>") +
          date_keys[i] +
          "</th>";
      }
      // th_tmpl.innerHTML=colsHTML;
      tableElement.innerHTML=`<tr><th>${th_tmpl.textContent}</th>${colsHTML}</tr>`;
      // tableElement.append(th_tmpl);
      var livematrixdata;
      var livematrixindex = midpoint.index;
      if (livematrixindex == 0) {
        Math.ceil(livematrix.length / 2);
      }
      midpoint = ric_data?.underlying_bid_price.replace(",", "");
      midpoint = Number((Number(midpoint) * 2).toFixed(0)) / 2;
      for (var i = livematrix.length - 1; i >= 0; i--) {
        var rowclass = "";
        livematrixdata = livematrix[i];
        for (var key in livematrixdata) {
          var bids = livematrixdata[key];
          for (var j = total_keys - 1; j >= 0; j--) {
            if (bids[date_keys[j]] <= 0.01) {
              rowclass = "rownotdisplay ";
              break;
            }
          }
        }
        for (var key in livematrixdata) {
          if (Number(key).toFixed(1) == midpoint.toFixed(1)) {
            rowclass += " midpoint";
          }
          tablecontent += "<tr class=" + rowclass + ">";
          tablecontent +=
            "<td style='border-right:1px solid #ccc'>" + key + "</td>";
          var bids = livematrixdata[key];
          for (var j = total_keys - 1; j >= 0; j--) {
            var hide_class = "";
            if (j < 2 && total_keys > 3) {
              hide_class = "hide-xs-portrait";
            }
            if (holidays.indexOf(date_keys[j]) > -1) {
              tablecontent +=
                "<td class='bgcolor-01 " +
                hide_class +
                "'>" +
                (typeof bids[date_keys[j]] === "undefined"
                  ? "-"
                  : Number(bids[date_keys[j]]).toFixed(2)) +
                "</td>";
            } else if (hide_date && hide_date == date_keys[j]) {
              tablecontent +=
                "<td class='bgcolor-02 " +
                hide_class +
                "'>" +
                (typeof bids[date_keys[j]] === "undefined"
                  ? "-"
                  : Number(bids[date_keys[j]]).toFixed(2)) +
                "</td>";
            } else {
              tablecontent +=
                "<td class='" +
                hide_class +
                "'>" +
                (typeof bids[date_keys[j]] === "undefined"
                  ? "-"
                  : Number(bids[date_keys[j]]).toFixed(2)) +
                "</td>";
            }
          }
          tablecontent += "</tr>";
        }
      }
      tableElement.innerHTML += tablecontent;
      ShowHideByClassNameOrID(".rownotdisplay", "none");

      if (
        tableElement.querySelector("tr.rownotdisplay").length + 1 ==
        tableElement.querySelectorAll("tr").length
      ) {
        if (ric_data?.issuer == "MACQ") {
          if (getMessage["status"] == "0") {
            ShowHideByClassNameOrID("#text-content .table_livematrix_fiveday_empty tr", "");
            tableElement.innerHTML = document.querySelector(
              "#text-content .table_livematrix_fiveday_empty tr"
            ).innerHTML;
          } else {
            ShowHideByClassNameOrID("#text-content .table_livematrix_fiveday_empty tr", "none");
          }
        } else {
          if (getMessage["status"] == "0") {
            ShowHideByClassNameOrID("#text-content .table_livematrix_none_mq tr", "");
            tableElement.innerHTML = document.querySelector(
              "#text-content .table_livematrix_none_mq tr"
            ).innerHTML;
          } else {
            ShowHideByClassNameOrID("#text-content .table_livematrix_none_mq tr", "none");
          }
        }
      } else {
        tableElement
          .querySelector("tr:visible")
          .filter(":even")
          .addClass("bgcolor-08");
      }
    } else if (data1.is_compressed) {
      var fdates = data1.fdates;
      var dates = data1.dates;
      var holidays = data1.holidays;
      var rollOver = [];
      if (
        ric_data?.ticker == "S5028C1907A" ||
        ric_data?.ticker == "S5028C1908A" ||
        ric_data?.ticker == "S5028P1907A" ||
        ric_data?.ticker == "S5028P1908A"
      ) {
        rollOver.push("24 Jun");
      }
      if (
        ric_data?.ticker == "S5028C1910A" ||
        ric_data?.ticker == "S5028C1912A" ||
        ric_data?.ticker == "S5028C1912B" ||
        ric_data?.ticker == "S5028C1912C" ||
        ric_data?.ticker == "S5028P1910A" ||
        ric_data?.ticker == "S5028P1912A" ||
        ric_data?.ticker == "S5028P1912B"
      ) {
        rollOver.push("23 Sep");
      }
      tablecontent += "<tr>";
      for (var i = fdates.length; i > 0; i--) {
        if (i < 3) {
          tablecontent +=
            "<td class='subtable hide-xs-portrait t" + i + "'></td>";
        } else {
          tablecontent += "<td class='subtable t" + i + "'></td>";
        }
      }
      var max = 0;
      var min = 0;
      var ric_bid = ric_data?.BID;
      for (var i = 0; i < dates.length; i++) {
        var length = data1[dates[i]].length;
        var first_bid = Number(data1[dates[i]][0]["bid"]);
        var last_bid = Number(data1[dates[i]][length - 1]["bid"]);
        if (first_bid > last_bid) {
          if (i == 0) {
            max = first_bid;
            min = last_bid;
          } else {
            max = first_bid > max ? max : first_bid;
            min = last_bid < min ? min : last_bid;
          }
        } else {
          if (i == 0) {
            min = first_bid;
            max = last_bid;
          } else {
            max = last_bid > max ? max : last_bid;
            min = first_bid < min ? min : first_bid;
          }
        }
      }
      tablecontent += "</tr>";
      tableElement.innerHTML = tablecontent;
      for (var i = 0; i < dates.length; i++) {
        var innerTable = "";
        midpoint = findMidPoint(
          data1[dates[i]],
          ric_data?.lmuprice,
          data1.is_compressed,
          true,
          ric_data?.type
        );
        if (fdates[i]) {
          innerTable +=
            "<table class='innertable' cellpadding='0' cellspacing='0' style='width:100%'>";
          innerTable += "<tr><th colspan='2'>" + fdates[i] + "</th></tr>";
          var scount = 1,
            lcount = 1;
          for (var j = 0; j < data1[dates[i]].length; j++) {
            var hrow = data1[dates[i]][j];
            var trow = data1[dates[i]][j + 1];
            var bid = Number(hrow["bid"]);
            if (bid > max || bid < min) {
              j++;
            } else {
              if (!isNaN(hrow[ubidask])) {
                hrow[ubidask] = Number(hrow[ubidask]);
              }
              if (hrow && trow) {
                if (hrow.head_tail_id == trow.head_tail_id) {
                  var classrow = "";
                  if (hrow[ubidask] == midpoint.price) {
                    classrow += "";
                    if (hrow.bid <= 0.01) {
                      classrow = "rownotdisplay";
                    }
                  } else if (hrow[ubidask] < midpoint.price) {
                    classrow += "srow_" + i + " srow_" + i + "_" + scount;
                    if (hrow.bid <= 0.01) {
                      classrow += " rownotdisplay";
                    }
                    scount++;
                  } else if (hrow[ubidask] > midpoint.price) {
                    classrow = "lrow_" + i + " lrow_" + i + "_" + lcount;
                    lcount++;
                  }
                  if (holidays.indexOf(fdates[i]) != -1) {
                    classrow += " skipholiday";
                  }
                  if (rollOver.indexOf(fdates[i]) != -1) {
                    classrow += " hidematrix";
                  }
                  if (hide_date && fdates[i] == hide_date) {
                    classrow += " hidematrix";
                  }
                  if (ric_bid == bid) {
                    classrow += " highlightbid";
                  }
                  innerTable +=
                    "<tr class='" +
                    classrow +
                    "' ><td class='t" +
                    (i + 1) +
                    "r" +
                    j +
                    "'>";
                  innerTable +=
                    Number(trow[ubidask]).toFixed(2) +
                    " - " +
                    Number(hrow[ubidask]).toFixed(2);
                  if (
                    classrow.indexOf("skipholiday") !== -1 ||
                    classrow.indexOf("hidematrix") !== -1
                  ) {
                    innerTable += "</td><td>";
                  } else {
                    innerTable += "</td><td class='bgcolor-07'>";
                  }
                  innerTable += Number(hrow.bid).toFixed(2);
                  innerTable += "</td></tr>";
                  j++;
                }
              }
            }
          }
          innerTable += "</table>";
        } else {
          innerTable +=
            "<table class='innertable' cellpadding='0' cellspacing='0' style='width:100%'>";
          innerTable += "<tr><th colspan='2'>" + fdates[i] + "</th></tr>";
          if (data1[dates[i]]) {
            for (var j = 0; j < 17; j++) {
              innerTable += "<tr class='skipholiday'><td>-</td><td>-</td></tr>";
            }
          }
          innerTable += "</table>";
        }
        document.querySelector(".t" + (i + 1)).textContent = innerTable;
        if (ric_data?.BID == 0.04) {
          tableElement.querySelector(".srow_" + i).style.display = "none";
        }
        document
          .querySelector(".t" + (i + 1))
          .querySelector(".rownotdisplay").style.display = "none";
        document
          .querySelector(".t" + (i + 1))
          .querySelector("tr:visible")
          .filter(":even")
          .addClass("bgcolor-08");
      }
    } else {
      var livematrix = data1.livematrix;
      if (livematrix?.length == 0) {
        if (ric_data?.issuer == "MACQ") {
          if (getMessage["status"] == "0") {
            document.querySelector(
              "#text-content .table_livematrix_fiveday_empty tr"
            ).style.display = "";
            tableElement.innerHTML = document.querySelector(
              "#text-content .table_livematrix_fiveday_empty tr"
            ).innerHTML;
          } else {
            document.querySelector(
              "#text-content .table_livematrix_fiveday_empty tr"
            ).style.display = "none";
          }
        } else {
          if (getMessage["status"] == "0") {
            document.querySelector(
              "#text-content .table_livematrix_none_mq tr"
            ).style.display = "";
            tableElement.innerHTML = document.querySelector(
              "#text-content .table_livematrix_none_mq tr"
            ).innerHTML;
          } else {
            document.querySelector(
              "#text-content .table_livematrix_none_mq tr"
            ).style.display = "none";
          }
        }
        return
      }

      var date_keys = data1?.date_keys;
      var total_keys = date_keys?.length;
      var holidays = data1?.holidays;
      midpoint = findMidPoint(
        livematrix,
        ric_data?.lmuprice,
        data1?.is_compressed,
        true,
        ric_data?.type
      );
      var livematrixlimit = 9;
      var th_tmpl = document.querySelector("#five_day_table_tmpl tr");
      var colsHTML = "";
      for (var i = total_keys - 1; i >= 0; i--) {
        colsHTML +=
          (i < 2 && total_keys > 3 ? "<th class='hide-xs-portrait'>" : "<th>") +
          date_keys[i] +
          "</th>";
      }
      th_tmpl.append(colsHTML);
      tableElement.innerHTML += th_tmpl;
      var livematrixdata;
      var livematrixindex = midpoint.index;

      if (livematrixindex == 0) {
        Math.ceil(livematrix.length / 2);
      }

      for (var i = 0; i < livematrix.length; i++) {
        var rowclass = "";
        livematrixdata = livematrix[i];

        for (var key in livematrixdata) {
          var bids = livematrixdata[key];
          for (var j = (total_keys - 1); j >= 0; j--) {
            if (bids[date_keys[j]] <= 0.01) {
              rowclass = "rownotdisplay ";
              break;
            }
          }
        }

        for (var key in livematrixdata) {

          tablecontent += (key == midpoint.price) ? "<tr class=" + rowclass + " midpoint" + ">" : "<tr class=" + rowclass + ">";
          tablecontent += "<td style='border-right:1px solid #ccc'>" + key + "</td>";
          var bids = livematrixdata[key];
          for (var j = (total_keys - 1); j >= 0; j--) {
            var hide_class = "";
            if (j < 2 && total_keys > 3) {
              hide_class = "hide-xs-portrait";
            }
            if (holidays.indexOf(date_keys[j]) > -1) {
              tablecontent += "<td class='bgcolor-01 " + hide_class + "'>" + (typeof bids[date_keys[j]] === 'undefined' ? '-' : Number(bids[date_keys[j]]).toFixed(2)) + "</td>";
            } else if (hide_date && hide_date == date_keys[j]) {
              tablecontent += "<td class='bgcolor-02 " + hide_class + "'>" + (typeof bids[date_keys[j]] === 'undefined' ? '-' : Number(bids[date_keys[j]]).toFixed(2)) + "</td>";
            } else {
              tablecontent += "<td class='" + hide_class + "'>" + (typeof bids[date_keys[j]] === 'undefined' ? '-' : Number(bids[date_keys[j]]).toFixed(2)) + "</td>";
            }
          }
          tablecontent += "</tr>";
        }
      }
      tableElement.innerHTML += tablecontent;
      ShowHideByClassNameOrID(".rownotdisplay", "none");
      if (tableElement.querySelectorAll("tr.rownotdisplay").length + 1 ==
        tableElement.querySelectorAll("tr").length) {
        if (ric_data.issuer == "MACQ")
          if (getMessage["status"] == "0") {
            ShowHideByClassNameOrID("#text-content .table_livematrix_fiveday_empty tr", "");
            tableElement.innerHTML = document.querySelector(
              "#text-content .table_livematrix_fiveday_empty tr"
            ).innerHTML;
          } else {
            ShowHideByClassNameOrID("#text-content .table_livematrix_fiveday_empty tr", "none");
          }
        else
          if (getMessage["status"] == "0") {
            ShowHideByClassNameOrID("#text-content .table_livematrix_none_mq tr", "");
            tableElement.innerHTML = document.querySelector(
              "#text-content .table_livematrix_none_mq tr"
            ).innerHTML;
          } else {
            ShowHideByClassNameOrID("#text-content .table_livematrix_none_mq tr", "none");
          }
      } else {

        var rows = tableElement.querySelectorAll('tr:not([style*="display: none"])');
        var evenRows = [];
        for (var i = 0; i < rows.length; i++) {
          if (i % 2 === 0) {
            evenRows.push(rows[i]);
          }
        }

        evenRows.forEach(function (row) {
          row.classList.add('bgcolor-08');
        });
      }
    }
  };

  const findMidPoint = (lmdata, lmprice, iscompress, isfiveday, type) => {
    var mp = {
      price: -1,
      diff: -1,
      index: -1,
    };
    for (var i = 0; i < lmdata?.length; i++) {
      if (iscompress && !(i % 2 == 0)) {
        continue;
      }
      var ubid;
      if (isfiveday && !iscompress) {
        ubid = Object.keys(lmdata[i])[0];
      } else {
        ubid = lmdata[i][type == "PUT" ? "underlying_ask" : "underlying_bid"];
      }
      var diff = lmprice ? Math.abs(lmprice.replace(/,/g, "") - ubid) : -1;
      if (mp.index === -1 || mp.diff > diff) {
        mp.diff = diff;
        mp.price = ubid;
        mp.index = i;
      }
    }
    return mp;
  };


  //update Page
  const updatePage = (ric_data) => {
    setRicDataAll(ric_data);
    var underlying_ticker = ric_data?.underlying_ticker;
    if (
      underlying_ticker == "SET50*" ||
      underlying_ticker == "SET50" ||
      underlying_ticker == "HSI" ||
      underlying_ticker == "HSTECH" ||
      underlying_ticker == "HSCEI" ||
      underlying_ticker == "S&P500" ||
      underlying_ticker == "DJI" ||
      underlying_ticker == "NDX"
    ) {
      ShowHideByClassNameOrID(".indextable", "");
      ShowHideByClassNameOrID(".sstable", "none");
    } else {
      ShowHideByClassNameOrID(".indextable", "none");
      ShowHideByClassNameOrID(".sstable", "");
    }
    var bid = Number(ric_data?.BID);
    var theta = Math.abs(Number(ric_data?.theta));
    var tdbpd = (bid * theta) / 100;
    document.querySelector(".sstable #tdbpd").textContent = tdbpd.toFixed(4);
    document.querySelector(".indextable #tdbpd").textContent = tdbpd.toFixed(4);
    var tdndpt = 0;
    if (tdbpd != 0) {
      tdndpt = 0.01 / tdbpd;
    } else {
      document.querySelector(".sstable #tdbpd").textContent = tdbpd.toFixed(0);
      document.querySelector(".indextable #tdbpd").textContent =
        tdbpd.toFixed(0);
    }
    if (tdndpt > 1) {
      document.querySelector(".sstable #tdndpt").textContent =
        tdndpt.toFixed(0);
      document.querySelector(".indextable #tdndpt").textContent =
        tdndpt.toFixed(0);
    } else {
      document.querySelector(".sstable #tdndpt").textContent =
        tdndpt.toFixed(1);
      document.querySelector(".indextable #tdndpt").textContent =
        tdndpt.toFixed(1);
    }
    if (ric_data?.sensitivity == "N/A") {
      document.querySelector(".indextable #sensitivitybyindex").textContent =
        "N/A";
    } else {
      let sensitivity = Number(ric_data?.sensitivity_cal);
      if (sensitivity == 0) {
        document.querySelector(".indextable #sensitivitybyindex").textContent =
          "N/A";
      } else {
        var sensitivitybyindex = 1.0 / sensitivity;
        document.querySelector(".indextable #sensitivitybyindex").textContent =
          sensitivitybyindex.toFixed(2);
      }
    }
  };

  //runLiveHSI
  const runLiveHSI = async () => {
    var holders = document.querySelectorAll(".holder-indexfuture");
    try {
      let data;
      const response = await fetch(
        `${config.livenewsdata1}?ric=HSI`
      );
      if (response) {
        data = await response.json();
        if (data) {
          holders.forEach(async (holder) => {
            var table = holder.querySelector("table");
            var ticktime = holder.querySelector(".ticktime");
            if (data) {
              var ric = data.code.toString().toUpperCase();
              var therow = table.querySelector(".row_" + ric);
              var output = new Intl.NumberFormat();
              output.format(data.last);
              therow.querySelector(".col_month").textContent = data.month;
              therow.querySelector(".col_last").textContent = output.format(data.last);
              var chng = data.chng;
              if (chng > 0) {
                chng = "+" + chng;
              }

              var pchng = data.pchng;
              if (pchng.indexOf("%") >= 0) {
                pchng = pchng.substr(0, pchng.length - 1);
              }
              if (pchng > 0) {
                pchng = "+" + pchng;
              }

              var colChng = therow.querySelector(".col_chng");
              colChng.classList.remove("up", "down");
              colChng.classList.add(getUpDownClass(data.chng));
              colChng.textContent = chng;

              var colPChng = therow.querySelector(".col_pchng");
              colPChng.classList.remove("up", "down");
              colPChng.classList.add(getUpDownClass(data.chng));
              colPChng.textContent = pchng;

              var update_time = data.stime.split(" ");
              var date = new Date(data.stime);
              var hours = date.getHours() + 1;
              var min = date.getMinutes();
              var today = new Date();
              var s = today.getSeconds();
              if (s < 10) {
                s = "0" + s;
              }
              hours = hours - 2;
              var ampm = "AM";
              if (hours > 11) {
                // if (hours !== 12) {
                //   hours = hours - 12;
                // }
                ampm = "PM";
              }
              if (hours < 10) {
                hours = "0" + hours;
              }
              if (min < 10) {
                min = "0" + min;
              }
              ticktime.textContent = hours + ":" + min + ":" + s + " " + ampm;
            }
          });
        }
      }


    } catch (error) {
      console.error(error)
    }
  };

  //runLiveHSTECH
  const runLiveHSTECH = async () => {
    try {
      let holders = document.querySelectorAll(".holder-indexfuture");
      let data;
      const response = await fetch(
        `${config.livenewsdata1}?ric=HTI`
      );
      if (response) {
        data = await response.json();
        if (data) {
          holders.forEach(async (holder) => {
            let table = holder.querySelector("table");
            let ticktime = holder.querySelector(".ticktime");

            var ric = data.code.toString().toUpperCase();
            var therow = table.querySelector(".row_HSTECH");

            var output = new Intl.NumberFormat();
            output.format(data.last);
            therow.querySelector(".col_month").textContent = data.month;
            therow.querySelector(".col_last").textContent = output.format(data.last);

            var chng = data.chng;
            if (chng > 0) {
              chng = "+" + chng;
            }

            var pchng = data.pchng;
            if (pchng.indexOf("%") >= 0) {
              pchng = pchng.substr(0, pchng.length - 1);
            }
            if (pchng > 0) {
              pchng = "+" + pchng;
            }

            var colChng = therow.querySelector(".col_chng");
            colChng.classList.remove("up", "down");
            colChng.classList.add(getUpDownClass(data.chng));
            colChng.textContent = chng;

            var colPChng = therow.querySelector(".col_pchng");
            colPChng.classList.remove("up", "down");
            colPChng.classList.add(getUpDownClass(data.chng));
            colPChng.textContent = pchng;

            var update_time = data.stime.split(" ");
            var date = new Date(data.stime);
            var hours = date.getHours() + 1;
            var min = date.getMinutes();
            var today = new Date();
            var s = today.getSeconds();
            if (s < 10) {
              s = "0" + s;
            }
            hours = hours - 1;
            var ampm = "AM";
            if (hours > 11) {
              if (hours !== 12) {
                hours = hours - 12;
              }
              ampm = "PM";
            }
            if (hours < 10) {
              hours = "0" + hours;
            }
            if (min < 10) {
              min = "0" + min;
            }
            ticktime.textContent = hours + ":" + min + ":" + s + " " + ampm;
          })
        }
      }


    } catch (error) {
      console.error(error)
    }
  };


  const runLiveHHI = async () => {
    try {
      let holders = document.querySelectorAll(".holder-indexfuture");
      const response = await fetch(
        `${config.livenewsdata1}?ric=HHI`
      );
      if (response) {
        let data = await response.json();
        if (data) {
          holders.forEach(async (holder) => {
            let table = holder.querySelector("table");
            let ticktime = holder.querySelector(".ticktime");

            var ric = data.code.toString().toUpperCase();
            var therow = table.querySelector(".row_HHI");

            var output = formatNumberCustom(data.last, 0);
            therow.querySelector(".col_month").innerHTML = data.month;
            therow.querySelector(".col_last").innerHTML = output;

            var chng = data.chng > 0 ? "+" + data.chng : data.chng;
            var pchng = data.pchng.indexOf("%") >= 0 ? data.pchng.slice(0, -1) : data.pchng;
            pchng = pchng > 0 ? "+" + pchng : pchng;

            var chngClass = data.chng;
            therow.querySelector(".col_chng").classList.remove("up", "down");
            therow.querySelector(".col_chng").classList.add(getUpDownClass(chngClass));
            therow.querySelector(".col_chng").innerHTML = chng;
            therow.querySelector(".col_pchng").classList.remove("up", "down");
            therow.querySelector(".col_pchng").classList.add(getUpDownClass(chngClass));
            therow.querySelector(".col_pchng").innerHTML = pchng;

            var update_time = data.stime.split(" ");
            var date = new Date(data.stime);
            var hours = date.getHours();
            var min = date.getMinutes();
            var today = new Date();
            var s = today.getSeconds();
            if (s < 10) {
              s = "0" + s;
            }

            var ampm = "AM";
            if (hours > 11) {
              if (hours != 12) {
                hours = hours - 12;
              }
              ampm = "PM";
            }
            if (hours < 10) {
              hours = "0" + hours;
            }
            if (min < 10) {
              min = "0" + min;
            }
            ticktime.innerHTML = hours + ":" + min + ":" + s + " " + ampm;

          })
        }
      }

    } catch (error) {
      console.error(error)
    }

  };

  //formatNumberCustom
  function formatNumberCustom(number, decimalPlaces) {
    var multiplier = Math.pow(10, decimalPlaces);
    return Math.round(number * multiplier) / multiplier;
  }

  //getUpDownClass
  const getUpDownClass = (value) => {
    if (value < 0) {
      return "down";
    } else if (value > 0) {
      return "up";
    }
  }

  const myRefToPrint = useRef(null);
  // const myRef2 = useRef(null)
  // const handlePrint = useReactToPrint({
  //     content: () => myRefToPrint.current
  // });
  const handlePrint = () => {
    window.print();
  }

  // handlePrintClick
  const handlePrintClick = () => {
    const pageTitle = `${document.title} | ${underlyingric}`;
    const lmTableContainer = document.getElementById('lmtablecontainer');
    if (lmTableContainer) {
      handlePrint();
    }
  };

  /// refresh Table on Click
  const refreshTable = async (leftRightClass) => {
    setLoadingTable(true);
    document.querySelector("#lmtablecontainer").style.display = "none"
    let lmtabArray = document.querySelectorAll(".lmtab");
    if (leftRightClass == "lmtab-right") {
      lmtabArray[0].classList.remove("pressed")
      lmtabArray[1].classList.add("pressed")
      if (symbol.toString().indexOf("HSI") == -1) {
        ShowHideByClassNameOrID(".hsi_item", "none");
        ShowHideByClassNameOrID(".sp500_item", "none");
        ShowHideByClassNameOrID(".today_item", "none");
        ShowHideByClassNameOrID(".dji_item", "none");
        ShowHideByClassNameOrID(".ndx_item", "none");
        ShowHideByClassNameOrID(".hscei_item", "none");
        ShowHideByClassNameOrID(".hstech_item", "none");
        ShowHideByClassNameOrID(".fiveday_item", "");
        ShowHideByClassNameOrID("#today_table", "none");
        ShowHideByClassNameOrID("#five_day_table", "");
        updateFivedayData(ricToStore);
      }
    } else if (leftRightClass == "lmtab-left") {
      lmtabArray[1].classList.remove("pressed")
      lmtabArray[0].classList.add("pressed")
      ShowHideByClassNameOrID(".hsi_item", "none");
      ShowHideByClassNameOrID(".sp500_item", "none");
      ShowHideByClassNameOrID(".dji_item", "none");
      ShowHideByClassNameOrID(".ndx_item", "none");
      ShowHideByClassNameOrID(".hscei_item", "none");
      ShowHideByClassNameOrID(".hstech_item", "none");
      ShowHideByClassNameOrID(".today_item", "");
      ShowHideByClassNameOrID(".fiveday_item", "none");
      ShowHideByClassNameOrID("#today_table", "");
      ShowHideByClassNameOrID("#five_day_table", "none");
      const response = await fetch(`${config.LiveMatrix}?ric=${ricToStore}&mode=0`);
      if (response) {
        let data = await response.json();
        updateTodayData(data);
      }

    }
    setTimeout(() => {
      document.querySelector("#lmtablecontainer").style.display = ""
      setLoadingTable(false);
    }, 2000)


  }


  //hsiimage
  const hsiimage = async () => {
    let data = {
      hsi: "6102",
    };
    let livematriximage = await api.get(config.advImgData, true, data);

    setImage(<img src={livematriximage} alt={livematriximage} />);
  };

  const getWarrantToolsLiveMatrixlastview = async () => {
    let data = {
      type: "lastview",
      ric: JSON.parse(window.localStorage.getItem("ric"))
        ? JSON.parse(window.localStorage.getItem("ric")).slice(0, 6).toString()
        : "",
    };
    let LiveMatrixlastviewResult = await api.get(
      config.marketTurnOverList,
      true,
      data
    );

    let rwlistRes = get(LiveMatrixlastviewResult, "dwList", []);
    let lvlistRes = get(LiveMatrixlastviewResult, "lvList", []);
    setLvList(lvlistRes);
    setRwList(rwlistRes);
    setLoadingLV(false)
  };

  // getAndSetRicDetails
  const getAndSetRicDetails = async (Ric, where) => {
    if(Ric==null){
      Ric=""
    }
    const response = await fetch(`${config.LiveMatrix}?ric=${Ric}&mode=0`);
    if (response) {
      let parsedData = await response.json();
      if (parsedData) {
        const { symbols } = parsedData
        if (Ric != undefined && symbols.length > 0) {
          if (where == "url") {
            let ricData = symbols.filter((rk) => Ric === rk.ric);
            setRicToShow1(ricData[0].security_code)
            setSymbol(ricData[0].security_code)
            setRicToStore(ricData[0].ric)
            setData(parsedData)
            setLastUpdateDate(printFormattedDate(parsedData.last_update, true, siteLang))
            return parsedData;
          } else if (where == "local") {
            let ricData = symbols.filter((rk) => Ric === rk.ric);
            setRicToShow1(ricData[0].security_code)
            setSymbol(ricData[0].security_code)
            setData(parsedData)
            setLastUpdateDate(printFormattedDate(parsedData.last_update, true, siteLang))
            setRicToStore(ricData[0].ric)
            return parsedData;
          } else {
            setRicToStore(symbols[0].ric)
            if (typeof parsedData != "object") {
              setLastUpdateDate(printFormattedDate(parsedData.last_update, true, siteLang))
            }
            setSymbol(symbols[0].security_code)
            setRicToShow1(symbols[0].security_code)
            setData(parsedData)
            return parsedData
          }

        }
      }
    }
  }

  //getRicDetailsAndSet
  const getRicDetailsAndSet = async (Ric, where) => {
    const response = await fetch(`${config.LiveMatrix}?ric=&mode=0`);
    if (response) {
      let responseData = await response.json();
        const { symbols } = responseData;
    if (where == "url" || where == "local") {
        let filteredData;
        if (where == "local") {
          filteredData = symbols.filter(item => item.ric.toLowerCase() == Ric.toLowerCase());
        } else {
          let filterX = symbols.filter(item => item.security_code.toLowerCase() == Ric.toLowerCase());
          if(filterX.length>0){
            filteredData = filterX;
          }else{
            filteredData = symbols;
          }
        }
        return getAndSetRicDetails(filteredData[0].ric, where)
      }else {
        return getAndSetRicDetails(symbols[0]?.ric, where)
      }
    } 
  }


  //get RIC from URL || LocalStorage || First Symbol RIC
  const getRicUrl = async () => {
    let queryString = window.location.pathname.split("/");
    let splitData = queryString[3];
    let RicFromLocalStorage = JSON.parse(window.localStorage.getItem("ric"));
    RicFromLocalStorage = RicFromLocalStorage?.filter(elements => {
      return (elements != null && elements !== undefined && elements !== "");
    });
    if (splitData) {
      return await getRicDetailsAndSet(splitData, "url");
    } else if (RicFromLocalStorage?.length > 0) {
      return await getRicDetailsAndSet(RicFromLocalStorage[0], "local");
    } else {
      return await getRicDetailsAndSet("", "nothing");
    }

  };    

  const colourStyles = {
    control: (provided) => ({
      ...provided,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      textAlign: 'center', // Center-align the text input
    }),
    input:(provided)=>({
      ...provided,
      width:"100%",
      display:"flex "
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: 'center', // Center-align the selected value
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#007fff" : null,
        color: "#333333",
        color: isFocused ? "#fff" : null,
        textAlign: "center",
        fontSize: siteLang == "eng" ? "14px" : "21px",
        zIndex: "1000",
        padding: "3px 10px",
        fontFamily:
          siteLang == "eng"
            ? "'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif"
            : "'DBHeaventLight','DBHeavent'",
        cursor: "pointer",
      };
    },
  };

  const _check_hsi_compare_table = (compare_table, value) => {
    if (compare_table && value) {
      for (var i = 0; i < compare_table.length; i++) {
        if (value <= compare_table[i].from && value >= compare_table[i].to) {
          return compare_table[i].bid;
        }
      }
    }
    return 0;
  }
  function toThousands(num) {
    num = num.toFixed(2);
    num = (num || 0).toString();
    let number = 0,
    floatNum = '',
    intNum = '';
    if (num.indexOf('.') > 0) {
      number = num.indexOf('.');
      floatNum = num.substr(number);
      intNum = num.substring(0, number);
    } else {
      intNum = num;
    }
    let result = [],
    counter = 0;
    intNum = intNum.split('');
    for (let i = intNum.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(intNum[i]);
      if (!(counter % 3) && i != 0) {
        result.unshift(',');
      }
    }
    return result.join('') + floatNum || '';
  }
  const getComparisonsData = async (val) => {
    setLoadingIndicator(true)
    if(!val){
      return false;
    }
    const ricValue =val.ric_data.underlying_ric;
    // var url = `https://t12a.trkd-hs.com/apimqth/ComparisonDataJSON?type=u&ric=${ricValue}&qid=${Date.now()}`
    var TickerVal =''
    if(ricValue=='HSIc1' || ricValue=='HSIc2'){
      TickerVal ='HSI JUN24'
      setHtiTime("none")
      setHsiTime("block")
    }else if(ricValue=='YMc1'){
      TickerVal  = 'DJI JUN24'
      setHtiTime("block")
      setHsiTime("none")
    }else if(ricValue=='NQc1'){
      TickerVal  =  'NDX JUN24'
      setHtiTime("block")
      setHsiTime("none")
    }else if(ricValue=='HHTIc1'){
      TickerVal  =  'HSTECH JUN24'
      setHtiTime("none")
      setHsiTime("block")
    }else if(ricValue==='ESc1'){
      TickerVal  =  'S&P500 JUN24'
      setHtiTime("block")
      setHsiTime("none")
    }else if(ricValue=='HCEIc1'){
      TickerVal  =  'HSCEI JUN24'
      setHtiTime("none")
      setHsiTime("block")
    }else{
      document.querySelector("#underlying").style.display='none';
      return false;
    }
    setTickerVal(TickerVal)
    var url = `${config.warrantComparison}?type=u&ric=${ricValue}&qid=${Date.now()}`
    if(ricValue != "ESc1" && ricValue != "ESc2"){
      if (ricValue == "HSIc1" || ricValue == "HSIc2") {
        // url = "https://t12a.trkd-hs.com/apimqth/LiveIndexJSON?ric=HSI"
        url = `${config.livenewsdata1}?ric=HSI`
      } else if(ricValue == "HHTIc1" || ricValue == "HHTIc2") {
        // url = "https://t12a.trkd-hs.com/apimqth/LiveIndexJSON?ric=HTI"
        url = `${config.livenewsdata1}?ric=HTI`
      }
    }
    let comparisionUrl = await api.get(url);
    setLoadingIndicator(false)
    if (!ricValue.startsWith("HSIc") && !ricValue.startsWith("HHTIc")) {
      var allData = comparisionUrl.comparison;
    } else {
      var allData = comparisionUrl;
    }
    var date = new Date();
    if (!ricValue.startsWith("HSIc") && !ricValue.startsWith("HHTIc")) {
      date = new Date((date.getTime() - (15*60*1000)));
    }
    var hours = date.getHours() + ((new Date().getTimezoneOffset() + 420) / 60);
    var min = date.getMinutes();
    var today = new Date();
    var s = today.getSeconds();
    if(s < 10){
      s = "0" + s;
    }
    if(hours == 16 && min == 31){
      s = "00";
    }
    if(hours < 10){
      hours = "0" + hours;
    }
    if(min < 10){
      min = "0" + min;
    }
    var ampm = "น.";
    var siteLanguagea = $.cookie('applang');
    if (siteLanguagea != "thai") {
      ampm = "";
    }
    document.querySelectorAll(".ticktime").forEach((e) => {
      e.innerHTML = hours + ":" + min + ":" + s + " " + ampm
    })
    if(allData && allData.length > 0){
      var uObj = allData[0];
      // var wObjs = (allData.length > 1) ? allData.slice(1, allData.length) : [];
      let date = new Date();
      // let newmonth = date.getMonth();
      let Month = [];
      let newyear = date.getFullYear();
      // let Year = String(newyear).substr(2, 4);
      if(uObj.ticker === "HSI"){
        uObj.dsply_name = "HSI Futures";
        Month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
      }else{
        uObj.dsply_name = "SPX";
        Month = ["MAR", "MAR", "MAR", "JUN", "JUN", "JUN", "SEP", "SEP", "SEP", "DEC", "DEC", "DEC"]
      }
      document.querySelector("#bidchg").classList.remove("down", "up")
      document.querySelector("#bidpchg").classList.remove("down", "up")
      document.querySelector("#bidval").innerHTML = uObj?.BID
      document.querySelector("#trfprcval").innerHTML = uObj?.TRDPRC_1
      document.querySelector("#bidchg").innerHTML = uObj?.NETCHNG_1
      document.querySelector("#bidchg").classList.add(uObj?.NETCHNG_1.toString() < 0 ? "down" : uObj?.NETCHNG_1.toString() > 0 ? "up" : "")
      document.querySelector("#bidpchg").innerHTML = uObj?.PCTCHNG+"%"
      document.querySelector("#bidpchg").classList.add(uObj?.PCTCHNG.toString() < 0 ? "down" : uObj?.PCTCHNG.toString() > 0 ? "up" : "")
      // document.querySelector("#trdvol").innerHTML = uObj.ACVOL_1_SCALED
    }else if(allData){
      document.querySelector("#bidchg").classList.remove("down", "up")
      document.querySelector("#bidpchg").classList.remove("down", "up")
      document.querySelector("#trfprcval").innerHTML = toThousands(Number(allData?.last))
      document.querySelector("#bidchg").innerHTML = allData?.chng
      document.querySelector("#bidchg").classList.add(allData?.chng.toString() < 0 ? "down" : allData?.chng.toString() > 0 ? "up" : "")
      document.querySelector("#bidpchg").innerHTML = allData?.pchng
      document.querySelector("#bidpchg").classList.add(allData?.pchng.replace('%','') < 0 ? "down" : allData?.pchng.replace('%','') > 0 ? "up" : "")
    }
  };
  return (
    <>

      <div id="livematrix" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">
                  {siteLang == "eng"
                    ? languageText.firstNavigationEn
                    : languageText.firstNavigation}
                </Link>
              </li>
              <li className="active">
                {siteLang == "eng"
                  ? languageText.secondNavigationEn
                  : languageText.secondNavigation}
              </li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <div className="page-header">
                  <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
                </div>
                <p id="abc">
                  {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
                <div id="top_area">
                  <div id="dropdown-livematrix">
                    <Select
                      className="lmcombobox select_align_center"
                      placeholder={
                        siteLang == "eng"
                          ? languageText.selectPlaceholderEn
                          : languageText.selectPlaceholder
                      }
                      onChange={(e) => selectChange(e)}
                      options={symbolList}
                      styles={colourStyles}
                    />
                  </div>
                  {loadingPContractImage ? <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><LoaderSpin isVisible={loadingPContractImage} /></div> : ""}
                  {
                    statusMsg==1 && <p style={{background:'#f3f3f4',textAlign:'center',padding:'20px 20px',marginTop: '5px'}}>
                        {siteLang == "eng"
                            ? allData['message'][5]['en_msg']
                            : allData['message'][5]['thai_msg']
                            }
                      </p>
                  }
                  <div id="p_contract_image" style={{ display: "none" }}>
                    <p
                      id="p-contract"
                      className="set50-contract"
                    // style={{ display: set50Contract }}
                    >
                      {siteLang == "eng"
                        ? languageText.set50ContractEn
                        : languageText.set50Contract}
                      <span className="text-bold"></span>
                    </p>
                    <p
                      id="p-contract"
                      className="hsi-contract"
                    // style={{ display: hsiContract }}
                    >
                      {siteLang == "eng"
                        ? languageText.hsiContractEn
                        : languageText.hsiContract}
                      <span className="text-bold"></span>
                    </p>
                    <div className="show-hk-ss" style={{ display: showHkSs }}>
                      <a className="hksslink" href={hkssLink} target="_blank">
                        <img
                          className="ficon"
                          src={
                            siteLang == "eng"
                              ? languageText.hkssImgLinkEn
                              : languageText.hkssImgLink
                          }
                          style={{ maxWidth: "100%" }}
                        />
                      </a>
                    </div>
                  </div>
                  <div className="show-index-sm holder-indexfuture">
                    <table className="table table-striped table-noHeaderborder">
                      <thead>
                        <tr>
                          <th style={{ padding: "2px" }}>
                            {siteLang == "eng"
                              ? languageText.tableIndexEn
                              : languageText.tableIndex}
                          </th>
                          <th style={{ padding: "2px" }}>
                            {siteLang == "eng"
                              ? languageText.tableContractEn
                              : languageText.tableContract}
                          </th>
                          <th style={{ padding: "2px" }}>
                            {siteLang == "eng"
                              ? languageText.tableLastEn
                              : languageText.tableLast}
                          </th>
                          <th style={{ padding: "2px" }}>
                            {siteLang == "eng"
                              ? languageText.tableChangeEn
                              : languageText.tableChange}
                          </th>
                          <th style={{ padding: "2px" }}>
                            {siteLang == "eng"
                              ? languageText.tableChangePercentEn
                              : languageText.tableChangePercent}
                          </th>
                          <th style={{ padding: "2px" }} />
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="row_HSI" style={{ display: "none" }}>
                          <td style={{ padding: "2px" }}>
                            <Link to="/tools/dwranking/HSI">HSI*</Link>
                          </td>
                          <td className="col_month" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td className="col_last" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td
                            className="col_chng val up"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td
                            className="col_pchng val up"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td style={{ padding: "2px" }}>
                            <img
                              className="refresh_index"
                              style={{
                                cursor: "pointer",
                                width: "30px",
                                margin: "4px 0",
                              }}
                              onClick={() => refreshIndex("HSI")}
                              src="/img/bullet/refresh.png"
                            />
                          </td>
                        </tr>
                        <tr className="row_HHI" style={{ display: "none" }}>
                          <td style={{ padding: "2px" }}>
                            <Link to="/tools/dwranking/HSCEI">HSCEI*</Link>
                          </td>
                          <td className="col_month" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td className="col_last" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td
                            className="col_chng val"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td
                            className="col_pchng val"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td style={{ padding: "2px" }}>
                            <img
                              className="refresh_index"
                              style={{
                                cursor: "pointer",
                                width: "30px",
                                margin: "4px 0",
                              }}
                              onClick={() => refreshIndex("HHI")}
                              src="/img/bullet/refresh.png"
                            />
                          </td>
                        </tr>
                        <tr
                          className="row_HSTECH"
                          style={{ display: "none" }}
                        >
                          <td style={{ padding: "2px" }}>
                            <Link to="/tools/dwranking/HSTECH">HSTECH*</Link>
                          </td>
                          <td className="col_month" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td className="col_last" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td
                            className="col_chng val"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td
                            className="col_pchng val"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td style={{ padding: "2px" }}>
                            <img
                              className="refresh_index"
                              style={{
                                cursor: "pointer",
                                width: "30px",
                                margin: "4px 0",
                              }}
                              onClick={() => refreshIndex("HSTECH")}

                              src="/img/bullet/refresh.png"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="priceUpdateInfo">
                      <em>
                        <span>
                          {siteLang == "eng"
                            ? languageText.priceUpdateInfoEn
                            : languageText.priceUpdateInfo}
                        </span>
                        <span className="ticktime"></span>
                        <br />
                        <span
                          className="ttip"
                          data-tip
                          data-for={"dbPowerTooltip12"}
                        >
                          HSI data provided by DB Power Online Limited
                          [Disclaimer]
                        </span>
                        <ReactTooltip
                          id={"dbPowerTooltip12"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p
                              className="tooltip-content"
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteLang == "eng"
                                    ? languageText.dbPowerTooltipEn
                                    : languageText.dbPowerTooltip,
                              }}
                            />
                          </div>
                        </ReactTooltip>
                      </em>
                    </p>
                  </div>
                  <div className="lmtabcontainer container-fluid">
                    <table className="lmtabtbl table">
                      <tbody>
                        <tr>
                          <td className="lmtab lmtab-left pressed" width="48%" onClick={() => refreshTable("lmtab-left")}>
                            {siteLang == "eng"
                              ? languageText.lmtabtblTdFirstEn
                              : languageText.lmtabtblTdFirst}
                          </td>
                          <td
                            className="lmtab-mid"
                            width="4%"
                          // style={{ display: lmtabMid }}
                          >
                            &nbsp;
                          </td>
                          <td
                            className="lmtab lmtab-right"
                            width="48%"
                            onClick={() => refreshTable("lmtab-right")}
                          >
                            {siteLang == "eng"
                              ? languageText.lmtabtblTdLastEn
                              : languageText.lmtabtblTdLast}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="ricbar bgcolor-03">
                    <div id="indicator">
                      <span id="ticker">{ricToShow1}</span>
                    </div>
                    <div id="last_update">
                      {siteLang == "eng" ? languageText.lastUpdateEn : languageText.lastUpdate}
                      :
                      <span>{lastUpdateDate == "" || lastUpdateDate == null || !lastUpdateDate ? "-" : lastUpdateDate}</span>
                    </div>
                  </div>
                  <div style={{ display: "none" }} id="orientationcontainer" className="show-xs-portrait">
                    <img src="/img/bullet/rotate.png" />
                    <span style={{ marginLeft: "20px" }}>
                      {siteLang == "eng" ? languageText.rotateViewEn : languageText.rotateView}
                    </span>
                  </div>
                  {
                    loadingTable ? (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><LoaderSpin isVisible={loadingTable} /></div>) : ""
                  }
                  <div id="lmtablecontainer" ref={myRefToPrint} style={{ display: loadingTable ? "none" : "", position: "relative" }}>
                    <table
                      id="today_table"
                      className="table lbtable"
                    // style={{ display: todayTable }}
                    >
                      <thead>
                        <tr>
                          <th
                            colSpan={4}
                            className="th-left"
                            style={{ borderBottom: "0px" }}
                          >
                            <img
                              onClick={handlePrintClick}
                              className="printimg"
                              src="/img/bullet/print.png"
                              alt="print live matrix"
                            />
                          </th>
                        </tr>
                        {/* <tr></tr> */}
                        <tr
                          className="today_head"
                        >
                          <th width="30%">
                            <span className="th-left">
                              {siteLang == "eng"
                                ? languageText.todayTableUnderlyingBidEn
                                : languageText.todayTableUnderlyingBid}
                            </span>
                          </th>
                          <th width="20%">
                            <a
                              style={{ display: "none" }}
                              className="icon-refresh color-hyperlink pull-right"
                            />
                            {siteLang == "eng"
                              ? languageText.todayTableDwBidEn
                              : languageText.todayTableDwBid}
                          </th>
                          <th width="20%">
                            <a
                              style={{ display: "none" }}
                              className="icon-refresh color-hyperlink pull-right"
                            />
                            {siteLang == "eng"
                              ? languageText.todayTableDwOfferEn
                              : languageText.todayTableDwOffer}
                          </th>
                          <th width="30%">
                            <a
                              style={{ display: "none" }}
                              className="icon-refresh color-hyperlink pull-right"
                            />
                            <span className="th-right">
                              {siteLang == "eng"
                                ? languageText.todayTableUnderlyingOfferEn
                                : languageText.todayTableUnderlyingOffer}
                            </span>
                          </th>
                        </tr>
                        <tr className="hsi_head" style={{ display: "none" }}>
                          {/* <th width="50%" className="hsi_put" style={{ display: hsiPut }}> */}
                          <th width="50%" className="hsi_put" style={{ display: "none" }}>
                            <span className="th-left">
                              {siteLang == "eng"
                                ? languageText.todayTableUnderlyingOfferEn
                                : languageText.todayTableUnderlyingOffer}
                            </span>
                          </th>
                          {/* <th width="50%" className="hsi_call" style={{ display: hsiCall }}> */}
                          <th width="50%" className="hsi_call" style={{ display: "none" }}>
                            <span className="th-left">
                              {siteLang == "eng"
                                ? languageText.todayTableUnderlyingBidEn
                                : languageText.todayTableUnderlyingBid}
                            </span>
                          </th>
                          <th width="50%">
                            <a
                              // style={{ display: hsiCall }}
                              style={{ display: "none" }}
                              className="icon-refresh color-hyperlink pull-right"
                            />
                            {siteLang == "eng"
                              ? languageText.todayTableDwBidEn
                              : languageText.todayTableDwBid}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      </tbody>
                    </table>
                    <table
                      id="five_day_table"
                      className="table lbtable"
                      style={{ display: "none" }}
                    >
                      <thead>
                        <tr>
                          <th colSpan="100%">
                            <img
                              onClick={handlePrintClick}
                              className="printimg"
                              src="/img/bullet/print.png"
                              alt="print live matrix"
                            />
                            <a
                              style={{ display: "none" }}
                              className="icon-refresh color-hyperlink pull-right"
                            />
                            <span>
                              {siteLang == "eng"
                                ? languageText.todayTableDwBidPriceEn
                                : languageText.todayTableDwBidPrice}
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                    <div id="lm_message_con">
                      <div className="lm_message_head">
                        <img
                          onClick={handlePrintClick}
                          className="printimg"
                          src="/img/bullet/print.png"
                          alt="print live matrix"
                        />
                        DW bid
                      </div>
                      <div className="lm_message" id="lm_message" ></div>
                    </div>
                  </div>
                  <div className="indicator " id="hsi-hide">
                    <div className="color-tip pull-left">
                      <div className="color-icon bgcolor-01 pull-left" />
                      <div className="tip-text">
                        {siteLang == "eng"
                          ? languageText.publicHolidayEn
                          : languageText.publicHoliday}
                      </div>
                    </div>
                    <div className="color-tip pull-left">
                      <div className="color-icon bgcolor-02 pull-left" />
                      <div className="tip-text">
                        {siteLang == "eng"
                          ? languageText.rollOverDayEn
                          : languageText.rollOverDay}
                      </div>
                    </div>
                  </div>
                  <p className="small index_futures">
                    {siteLang == "eng" ? languageText.indexFuturesInstructionEn : languageText.indexFuturesInstruction}
                  </p>
                </div>
                <div style={{ clear: "both" }} />
                <div id="bottom_area" className="livematrix_valid">
                  <div id="srtable">
                    <table className="table">
                      <tbody className="sstable"
                      >
                        <tr>
                          <th className="srtable-leftlbl bgcolor-01">
                            {siteLang == "eng"
                              ? languageText.sstableUnderlyingEn
                              : languageText.sstableUnderlying}
                          </th>
                          <td
                            id="underlying_ticker"
                            className="srtable-leftval bgcolor-01"
                          >
                            {ricDataAll && ricDataAll?.underlying_ticker}
                          </td>
                          <th className="srtable-rightlbl bgcolor-02">
                            {siteLang == "eng"
                              ? languageText.sstableEffectiveGearingEn
                              : languageText.sstableEffectiveGearing}
                          </th>
                          <td
                            id="effective_gearing"
                            className="srtable-rightval bgcolor-02"
                          >
                            {ricDataAll && ricDataAll?.effective_gearing}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {siteLang == "eng"
                              ? languageText.sstableTypeEn
                              : languageText.sstableType}
                          </th>
                          <td id="type">{ricDataAll && ricDataAll?.type}</td>
                          <th>
                            {siteLang == "eng"
                              ? languageText.sstableDeltaEn
                              : languageText.sstableDelta}
                          </th>
                          <td id="delta">{ricDataAll && ricDataAll?.delta}</td>
                        </tr>
                        <tr>
                          <th className="bgcolor-01">
                            {siteLang == "eng"
                              ? languageText.sstableStrikeEn
                              : languageText.sstableStrike}
                          </th>
                          <td id="exercise_price" className="bgcolor-01">
                            {ricDataAll && ricDataAll?.exercise_price}
                          </td>
                          <th className="bgcolor-02">
                            {siteLang == "eng"
                              ? languageText.sstableImpliedVolatilityEn
                              : languageText.sstableImpliedVolatility}
                          </th>
                          <td id="implied_volatility" className="bgcolor-02">
                            {ricDataAll && ricDataAll?.implied_volatility}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {siteLang == "eng"
                              ? languageText.sstableSensitivityEn
                              : languageText.sstableSensitivity}
                          </th>
                          <td id="sensitivity">
                            {ricDataAll && ricDataAll?.sensitivity}
                          </td>
                          <th>
                            {siteLang == "eng"
                              ? languageText.sstableLastTradingDateEn
                              : languageText.sstableLastTradingDate}
                          </th>
                          <td id="last_trading_date">
                            {ricDataAll && ricDataAll?.last_trading_date}
                          </td>
                        </tr>
                        <tr>
                          <th className="bgcolor-01">
                            Time Decay (Baht Per Day)
                          </th>
                          <td id="tdbpd" className="bgcolor-01"></td>
                          <th className="bgcolor-02 ">
                            Time Decay (Number of Day Per Tick)
                          </th>
                          <td id="tdndpt" className="bgcolor-02"></td>
                        </tr>
                      </tbody>
                      <tbody
                        className="indextable"
                        style={{ display: "none" }}
                      >
                        <tr>
                          <th className="srtable-rightlbl bgcolor-01"> 
                            {siteLang == "eng"
                              ? languageText.sstableTypeEn
                              : languageText.sstableType}
                          </th>
                          <td id="type" className="srtable-leftval bgcolor-01">
                            {ricDataAll && ricDataAll?.type}
                          </td>
                          <th className="srtable-rightlbl bgcolor-02">
                            {siteLang == "eng"
                              ? languageText.sstableEffectiveGearingEn
                              : languageText.sstableEffectiveGearing}
                          </th>
                          <td
                            id="effective_gearing"
                            className="srtable-rightval bgcolor-02"
                          >
                            {ricDataAll && ricDataAll?.effective_gearing}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {siteLang == "eng"
                              ? languageText.sstableStrikeEn
                              : languageText.sstableStrike}
                          </th>
                          <td id="exercise_price">
                            {ricDataAll && ricDataAll?.exercise_price}
                          </td>
                          <th>
                            {siteLang == "eng"
                              ? languageText.sstableDeltaEn
                              : languageText.sstableDelta}
                          </th>
                          <td id="delta">{ricDataAll && ricDataAll?.delta}</td>
                        </tr>
                        <tr>
                          <th className="bgcolor-01">
                            {siteLang == "eng"
                              ? languageText.sstableLastTradingDateEn
                              : languageText.sstableLastTradingDate}
                          </th>
                          <td id="last_trading_date" className="bgcolor-01">
                            {ricDataAll && ricDataAll?.last_trading_date}
                          </td>
                          <th className="bgcolor-02">
                            {siteLang == "eng"
                              ? languageText.sstableImpliedVolatilityEn
                              : languageText.sstableImpliedVolatility}
                          </th>
                          <td id="implied_volatility" className="bgcolor-02">
                            {ricDataAll && ricDataAll?.implied_volatility}
                          </td>
                        </tr>
                        <tr>
                          <th>
                            {siteLang == "eng"
                              ? languageText.sstableSensitivityEn
                              : languageText.sstableSensitivity}
                          </th>
                          <td id="sensitivity">
                            {ricDataAll && ricDataAll?.sensitivity}
                          </td>
                          <th>
                            {siteLang == "eng"
                              ? languageText.sstableSensitivityIndexEn
                              : languageText.sstableSensitivityIndex}
                          </th>
                          <td id="sensitivitybyindex"></td>
                        </tr>
                        <tr>
                          <th className="bgcolor-01">
                            Time Decay (Baht Per Day)
                          </th>
                          <td id="tdbpd" className="bgcolor-01"></td>
                          <th className="bgcolor-02">
                            Time Decay (Number of Day Per Tick)
                          </th>
                          <td id="tdndpt" className="bgcolor-02"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h3 className="today_item hsi_item sp500_item lmatrix_tbl_f">
                    {siteLang == "eng" ? languageText.todayLivePriceMatirxEn : languageText.todayLivePriceMatirx}
                  </h3>
                  <h3
                    className="fiveday_item lmatrix_tbl_f"
                    style={{ display: "none" }}
                  >
                    {siteLang == "eng" ? languageText.fiveDayPriceMatirxEn : languageText.fiveDayPriceMatirx}
                  </h3>
                  <p
                    className="today_item lmatrix_tbl_f"
                    dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.todayItemDescriptionEn : languageText.todayItemDescription}}
                  />
                  <p
                    className="fiveday_item lmatrix_tbl_f"
                    style={{ display: "none" }}
                    dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.fivedayItemDescriptionEn : languageText.fivedayItemDescription}}
                  />
                  <p
                    className="hsi_item sp500_item lmatrix_tbl_f"
                    dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiItemDescriptionEn : languageText.hsiItemDescription}}
                  />
                  <table className="table table-striped">
                    <thead>
                      <tr className="row_0">
                        <th>
                          {siteLang == "eng"
                            ? languageText.sstableUnderlyingEn
                            : languageText.sstableUnderlying}
                        </th>
                        <th>
                          {siteLang == "eng"
                            ? languageText.liveMatrixUnderlyingHead2En
                            : languageText.liveMatrixUnderlyingHead2Th}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={{ display: "none" }}>
                        <td />
                      </tr>
                      <tr>
                        <td>
                          {siteLang == "eng"
                            ? "HSI and HSTECH "
                            : "HSI และ HSTECH "}
                        </td>
                        <td>10:00 - 10:59, 12:00 - 15:29, 16:15 - 16:30</td>
                      </tr>
                      <tr>
                        <td>
                          {siteLang == "eng"
                            ? "DJI, NDX and SPX"
                            : "DJI, NDX และ SPX"}
                        </td>
                        <td>10:00 - 16:30</td>
                      </tr>
                      <tr>
                        <td>Hong Kong share</td>
                        <td>10:00 - 10:59, 12:00 - 14:59</td>
                      </tr>
                    </tbody>
                  </table>
                  <div id="lmatrix_tbl_f" className="hidden-sm hidden-xs">
                    <ol>
                      <li
                        className="today_item"
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl1liEn
                              : languageText.lmatrixTbl1li,
                        }}
                      />
                      <li
                        className="hsi_item sp500_item"
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl2liEn
                              : languageText.lmatrixTbl2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl3liEn
                              : languageText.lmatrixTbl3li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl4liEn
                              : languageText.lmatrixTbl4li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl5liEn
                              : languageText.lmatrixTbl5li,
                        }}
                      />
                    </ol>
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTbl1ParaEn
                            : languageText.lmatrixTbl1Para,
                      }}
                    />
                     <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTbl2ParaEn
                            : languageText.lmatrixTbl2Para,
                      }}
                    /> 
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTbl3ParaEn
                            : languageText.lmatrixTbl3Para,
                      }}
                    /> 
                    <hr />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.marketDelayEn
                            : languageText.marketDelay,
                      }}
                    />
                  </div>
                  <div id="lmatrix_tbl_f" className="hsi_item hidden-sm hidden-xs livematrix_disclaimer">
                    <ol>
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl2liEn
                              : languageText.lmatrixTbl2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi2liEn
                              : languageText.lmatrixTblHsi2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi3liEn
                              : languageText.lmatrixTblHsi3li,
                        }}
                      />
                      <li
                            dangerouslySetInnerHTML={{
                              __html:
                              siteLang == "eng"
                              ? languageText.lmatrixTbl5liEn
                              : languageText.lmatrixTbl5li,
                              }}
                      />
                    </ol>
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHsi1ParaEn
                            : languageText.lmatrixTblHsi1Para,
                      }}
                    />
                    <p
                      className="small"
                      style={{display:allData && allData.ric_data.underlying_ric.indexOf('.HK')!=-1?'none':'block'}}
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHsi2ParaEn
                            : languageText.lmatrixTblHsi2Para,
                      }}
                    />
                    <p
                        className="small"
                          dangerouslySetInnerHTML={{
                          __html:
                          siteLang == "eng"
                          ? languageText.lmatrixTbl3ParaEn
                          : languageText.lmatrixTbl3Para,
                          }}
                    />
                    <hr />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.marketDelayEn
                            : languageText.marketDelay,
                      }}
                    />
                  </div>
                  <div id="lmatrix_tbl_f" className="hscei_item hidden-sm hidden-xs livematrix_disclaimer"> 
                    <ol>
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl2liEn
                              : languageText.lmatrixTbl2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi2liEn
                              : languageText.lmatrixTblHsi2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi3liEn
                              : languageText.lmatrixTblHsi3li,
                        }}
                      />
                      <li
                          dangerouslySetInnerHTML={{
                            __html:
                            siteLang == "eng"
                            ? languageText.lmatrixTbl5liEn
                            : languageText.lmatrixTbl5li,
                            }}
                    />
                    </ol>
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHsi1ParaEn
                            : languageText.lmatrixTblHsi1Para,
                      }}
                    />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHscei2ParaEn
                            : languageText.lmatrixTblHscei2Para,
                      }}
                    />
                    <p
                        className="small"
                          dangerouslySetInnerHTML={{
                          __html:
                          siteLang == "eng"
                          ? languageText.lmatrixTbl3ParaEn
                          : languageText.lmatrixTbl3Para,
                          }}
                    />
                    <hr />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.marketDelayEn
                            : languageText.marketDelay,
                      }}
                    />
                  </div>
                  <div id="lmatrix_tbl_f" className="hstech_item hidden-sm hidden-xs livematrix_disclaimer">
                    <ol>
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl2liEn
                              : languageText.lmatrixTbl2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi2liEn
                              : languageText.lmatrixTblHsi2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi3liEn
                              : languageText.lmatrixTblHsi3li,
                        }}
                      />
                      <li
                            dangerouslySetInnerHTML={{
                              __html:
                              siteLang == "eng"
                              ? languageText.lmatrixTbl5liEn
                              : languageText.lmatrixTbl5li,
                              }}
                      />
                    </ol>
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHsi1ParaEn
                            : languageText.lmatrixTblHsi1Para,
                      }}
                    />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHstech2ParaEn
                            : languageText.lmatrixTblHstech2Para,
                      }}
                    />
                    <p
                        className="small"
                          dangerouslySetInnerHTML={{
                          __html:
                          siteLang == "eng"
                          ? languageText.lmatrixTbl3ParaEn
                          : languageText.lmatrixTbl3Para,
                          }}
                    />
                    <hr />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.marketDelayEn
                            : languageText.marketDelay,
                      }}
                    />
                  </div>
                  <div id="lmatrix_tbl_f2" className="sp500_item livematrix_disclaimer hidden-sm hidden-xs">
                    <ol>
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl2liEn
                              : languageText.lmatrixTbl2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi2liEn
                              : languageText.lmatrixTblHsi2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi3liEn
                              : languageText.lmatrixTblHsi3li,
                        }}
                      />
                      <li
                            dangerouslySetInnerHTML={{
                              __html:
                              siteLang == "eng"
                              ? languageText.lmatrixTbl5liEn
                              : languageText.lmatrixTbl5li,
                              }}
                      />
                    </ol>
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHsi1ParaEn
                            : languageText.lmatrixTblHsi1Para,
                      }}
                    />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblSp2ParaEn
                            : languageText.lmatrixTblSp2Para,
                      }}
                    />
                    <p
                        className="small"
                          dangerouslySetInnerHTML={{
                          __html:
                          siteLang == "eng"
                          ? languageText.lmatrixTbl3ParaEn
                          : languageText.lmatrixTbl3Para,
                          }}
                    />
                    <hr />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.marketDelayEn
                            : languageText.marketDelay,
                      }}
                    />
                  </div>
                  <div id="lmatrix_tbl_f2" className="dji_item livematrix_disclaimer hidden-sm hidden-xs">
                    <ol>
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl2liEn
                              : languageText.lmatrixTbl2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi2liEn
                              : languageText.lmatrixTblHsi2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi3liEn
                              : languageText.lmatrixTblHsi3li,
                        }}
                      />
                      <li
                            dangerouslySetInnerHTML={{
                              __html:
                              siteLang == "eng"
                              ? languageText.lmatrixTbl5liEn
                              : languageText.lmatrixTbl5li,
                              }}
                      />
                    </ol>
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHsi1ParaEn
                            : languageText.lmatrixTblHsi1Para,
                      }}
                    />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblDji2ParaEn
                            : languageText.lmatrixTblDji2Para,
                      }}
                    />
                    <p
                        className="small"
                          dangerouslySetInnerHTML={{
                          __html:
                          siteLang == "eng"
                          ? languageText.lmatrixTbl3ParaEn
                          : languageText.lmatrixTbl3Para,
                          }}
                    />
                    <hr />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.marketDelayEn
                            : languageText.marketDelay,
                      }}
                    />
                  </div>
                  <div id="lmatrix_tbl_f2" className="ndx_item livematrix_disclaimer hidden-sm hidden-xs">
                    <ol>
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTbl2liEn
                              : languageText.lmatrixTbl2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi2liEn
                              : languageText.lmatrixTblHsi2li,
                        }}
                      />
                      <li
                        dangerouslySetInnerHTML={{
                          __html:
                            siteLang == "eng"
                              ? languageText.lmatrixTblHsi3liEn
                              : languageText.lmatrixTblHsi3li,
                        }}
                      />
                      <li
                            dangerouslySetInnerHTML={{
                              __html:
                              siteLang == "eng"
                              ? languageText.lmatrixTbl5liEn
                              : languageText.lmatrixTbl5li,
                              }}
                      />
                    </ol>
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblHsi1ParaEn
                            : languageText.lmatrixTblHsi1Para,
                      }}
                    />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.lmatrixTblNdx2ParaEn
                            : languageText.lmatrixTblNdx2Para,
                      }}
                    />
                    <p
                        className="small"
                          dangerouslySetInnerHTML={{
                          __html:
                          siteLang == "eng"
                          ? languageText.lmatrixTbl3ParaEn
                          : languageText.lmatrixTbl3Para,
                          }}
                    />
                    <hr />
                    <p
                      className="small"
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.marketDelayEn
                            : languageText.marketDelay,
                      }}
                    />
                  </div>
                  <div className="panel-group visible-xs-block visible-sm-block" id="defination">
                    <div className="panel panel-default">
                      <div className="panel-heading" onClick={handleDisclaimer}>
                        <button>
                          <span className="pull-right" >
                            <img src="/img/bg/questionMark.png" alt="questionMark" />
                          </span>
                          <div
                            id="collapse_title3"
                            className="panel-title text-center color-primary"
                          >
                            {siteLang == "eng"
                              ? languageText.disclaimerHeadingEn
                              : languageText.disclaimerHeading}
                          </div>
                        </button>
                      </div>
                      <div
                        id="collapse_dwcompare3"
                        className={`panel-collapse collapse ${disclaimerDefination}`}
                      >
                        <div id="lmatrix_tbl_f" >
                          <ol>
                            <li
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteLang == "eng"
                                    ? languageText.lmatrixTbl2liEn
                                    : languageText.lmatrixTbl2li,
                              }}
                            />
                            <li
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteLang == "eng"
                                    ? languageText.lmatrixTblHsi2liEn
                                    : languageText.lmatrixTblHsi2li,
                              }}
                            />
                            <li
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteLang == "eng"
                                    ? languageText.lmatrixTblHsi3liEn
                                    : languageText.lmatrixTblHsi3li,
                              }}
                            />
                            <li
                              dangerouslySetInnerHTML={{
                                __html:
                                siteLang == "eng"
                                ? languageText.lmatrixTbl5liEn
                                : languageText.lmatrixTbl5li,
                                }}
                            />
                          </ol>
                          <p
                            className="small"
                            dangerouslySetInnerHTML={{
                              __html:
                                siteLang == "eng"
                                  ? languageText.lmatrixTblHsi1ParaEn
                                  : languageText.lmatrixTblHsi1Para,
                            }}
                          />
                          <p
                            className="small"
                            dangerouslySetInnerHTML={{
                              __html:
                                siteLang == "eng"
                                  ? languageText.lmatrixTblNdx2ParaEn
                                  : languageText.lmatrixTblNdx2Para,
                            }}
                          />
                          <p
                            className="small"
                            dangerouslySetInnerHTML={{
                              __html:
                              siteLang == "eng"
                              ? languageText.lmatrixTbl3ParaEn
                              : languageText.lmatrixTbl3Para,
                              }}
                          />
                          <hr />
                          <p
                            className="small"
                            dangerouslySetInnerHTML={{
                              __html:
                                siteLang == "eng"
                                  ? languageText.marketDelayEn
                                  : languageText.marketDelay,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <div id="lastview" className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">
                    {siteLang == "eng"
                      ? languageText.relatedDwEn
                      : languageText.relatedDw}
                  </h2>
                </div>
                {!loadingLV ?
                  <div id="relatedDWTbl">
                    <table className="table table-closed">
                      <thead>
                        <tr>
                          <th>DW Symbol </th>
                          <th>DW bid</th>
                          <th>Eff. gearing</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rwList.length > 0 ? rwList
                          ? rwList.map((item, index) => {
                            return (
                              <tr className="rowtmpl striped" key={index}>
                                <td
                                  className="col_tkr"
                                  style={{ fontWeight: "bold" }}
                                >
                                  <Link
                                    target="_blank"
                                    to={`/tools/livematrix/${item?.ticker}`}
                                  >
                                    {item?.ticker}
                                  </Link>
                                </td>
                                <td
                                  className="col_bd"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.BID ? item?.BID : "-"}
                                </td>
                                <td
                                  className="col_eg"
                                  style={{ textAlign: "center" }}
                                >
                                  {isNaN(item?.effective_gearing)
                                    ? "N/A"
                                    : Number(item?.effective_gearing).toFixed(
                                      1
                                    )}
                                </td>
                              </tr>
                            );
                          })
                          : "" : <tr><td colSpan="4">No Related DW</td></tr>}
                      </tbody>
                    </table>
                  </div>
                  :
                  (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <LoaderSpin isVisible={loadingLV} />
                  </div>)}



                <div className="page-header">
                  <h2 className="small">
                    {siteLang == "eng"
                      ? languageText.lastViewEn
                      : languageText.lastView}
                  </h2>
                </div>
                {!loadingLV ?
                  <div id="lastViewTbl">
                    <table className="table table-striped table-closed">
                      <thead>
                        <tr>
                          <th>DW Symbol</th>
                          <th>DW bid</th>
                          <th>Eff. gearing</th>
                        </tr>
                      </thead>
                      <tbody>

                        {lvList.length > 0 ? lvList
                          ? lvList.map((item, index) => {
                            return (
                              <tr className="rowtmpl" key={index}>
                                <td
                                  className="col_tkr"
                                  style={{ fontWeight: "bold" }}
                                >
                                  <Link
                                    target="_blank"
                                    to={`/tools/livematrix/${item?.ticker}`}
                                  >
                                    {item?.ticker?item?.ticker:"-"}
                                  </Link>
                                </td>
                                <td
                                  className="col_bd"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.BID ? item?.BID : "-"}
                                </td>
                                <td
                                  className="col_eg"
                                  style={{ textAlign: "center" }}
                                >
                                  {item?.effective_gearing?isNaN(item?.effective_gearing)
                                    ? "N/A"
                                    : Number(item?.effective_gearing).toFixed(
                                      1
                                    ):"-"}
                                </td>
                              </tr>
                            );
                          })
                          : "" : <tr><td colSpan="4" style={{ textAlign: "center" }}>No Last Views</td></tr>}
                      </tbody>
                    </table>
                  </div>
                  :
                  (<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <LoaderSpin isVisible={loadingLV} />
                  </div>)
                }
              </div>
              <div className="his-index">
                <div className="holder-indexfuture">
                  <div
                    id="indexfuture"
                    className="section qlink-section"
                  >
                    <div className="page-header">
                      <h1 className="small">
                        {siteLang == "eng"
                          ? languageText.indexFutureEn
                          : languageText.indexFuture}
                      </h1>
                    </div>
                    <table className="table table-striped table-noHeaderborder">
                      <thead>
                        <tr>
                          <th>
                            {siteLang == "eng"
                              ? languageText.indexFutureTableMarketEn
                              : languageText.indexFutureTableMarket}
                          </th>
                          <th>
                            {siteLang == "eng"
                              ? languageText.indexFutureTableContractEn
                              : languageText.indexFutureTableContract}
                          </th>
                          <th>
                            {siteLang == "eng"
                              ? languageText.indexFutureTableLastEn
                              : languageText.indexFutureTableLast}
                          </th>
                          <th>
                            {siteLang == "eng"
                              ? languageText.indexFutureTableChangeEn
                              : languageText.indexFutureTableChange}
                          </th>
                          <th>
                            {siteLang == "eng"
                              ? languageText.indexFutureTableChangePercentEn
                              : languageText.indexFutureTableChangePercent}
                          </th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="row_HSI" style={{ display: "none" }}>
                          <td style={{ padding: "2px" }}>
                            <Link to="/tools/dwranking/HSI">HSI*</Link>
                          </td>
                          <td className="col_month" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td className="col_last" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td
                            className="col_chng val up"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td
                            className="col_pchng val up"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td style={{ padding: "2px" }}>
                            <img
                              className="refresh_index"
                              style={{
                                cursor: "pointer",
                                width: "30px",
                                margin: "4px 0",
                              }}
                              onClick={() => refreshIndex("HSI")}
                              src="/img/bullet/refresh.png"
                            />
                          </td>
                        </tr>
                        <tr className="row_HHI" style={{ display: "none" }}>
                          <td style={{ padding: "2px" }}>
                            <Link to="/tools/dwranking/HSCEI">HSCEI*</Link>
                          </td>
                          <td className="col_month" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td className="col_last" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td
                            className="col_chng val"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td
                            className="col_pchng val"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td style={{ padding: "2px" }}>
                            <img
                              onClick={() => refreshIndex("HHI")}
                              className="refresh_index"
                              style={{ cursor: "pointer", width: "22px" }}
                              src="/img/bullet/refresh.png"
                            />
                          </td>
                        </tr>
                        <tr
                          className="row_HSTECH"
                          style={{ display: "none" }}
                        >
                          <td style={{ padding: "2px" }}>
                            <Link to="/tools/dwranking/HSTECH">HSTECH*</Link>
                          </td>
                          <td className="col_month" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td className="col_last" style={{ padding: "2px" }}>
                            -
                          </td>
                          <td
                            className="col_chng val"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td
                            className="col_pchng val"
                            style={{ padding: "2px" }}
                          >
                            -
                          </td>
                          <td style={{ padding: "2px" }}>
                            <img
                              className="refresh_index"
                              style={{ cursor: "pointer", width: "22px" }}
                              src="/img/bullet/refresh.png"
                              onClick={() => refreshIndex("HSTECH")}

                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p className="priceUpdateInfo">
                      <em>
                        <span>
                          {siteLang == "eng"
                            ? languageText.indexPriceUpdateInfoEn
                            : languageText.indexPriceUpdateInfo}
                        </span>
                        <br />
                        {siteLang == "eng"
                          ? languageText.indexPriceUpdateInfoPriceEn
                          : languageText.indexPriceUpdateInfoPrice}
                        {date1} <span className="ticktime" />
                        <br />
                        <p className="indicator-item">
                          <span className="icon-time indicator-item-icon" />
                          <span
                            className="ttip"
                            data-tip
                            data-for={"dbPowerTooltip"}
                          >
                            HSI data provided by DB Power Online Limited
                            [Disclaimer]
                          </span>
                        </p>
                        <ReactTooltip
                          id={"dbPowerTooltip"}
                          delayHide={100}
                          place="right"
                          type="warning"
                          effect="solid"
                          clickable={true}
                        >
                          <div className="bg-tooltip">
                            <p
                              className="tooltip-content"
                              dangerouslySetInnerHTML={{
                                __html:
                                  siteLang == "eng"
                                    ? languageText.dbPowerTooltipEn
                                    : languageText.dbPowerTooltip,
                              }}
                            />
                          </div>
                        </ReactTooltip>
                      </em>
                    </p>
                  </div>
                </div>
                <div className="holder-lmatrix" />
                <div className="holder-toolstoptradedstock" />
                <div className="row">
                  <div className="holder-facebook-iframe col-xs-6" />
                  <div className="holder-line col-xs-6" />
                </div>
                <div className="holder-qlink-dwcalculator" />
              </div>

              {/* <div className="section qlink-section" id="underlying" style={{padding:'20px 5px'}}>
                <div id="dwsummary" style={{position: 'relative'}}>
                  <div id="ticker" className="ricbar bgcolor-03">
                    {tickerVal}
                  </div>
                  <table id="pricetbl" className="uppertbl">
                    <colgroup>
                      <col style={{backgroundColor: '#E7E8E8', padding: '3px'}} />
                      <col style={{backgroundColor: '#F5F6F7', padding: '3px', textAlign: 'left'}} />
                      <col style={{backgroundColor: '#F5F6F7', padding: '3px', textAlign: 'right'}} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td className="uppertbl-col-bid" rowSpan={3}>
                          <div className="bidprice lbl">
                            {siteLang == "eng" ? languageText2.lastPriceEn : languageText2.lastPrice}
                          </div>
                          <div id="trfprcval" className="bidprice val" style={{fontSize:'30px'}}>
                            -
                          </div>
                          <div className="bidprice lbl HTI_time" style={{display: htiTime}}>
                            {siteLang == "eng" ? languageText2.lastBidPriceEn : languageText2.lastBidPrice}
                          </div>
                          <div id="bidval" className="bidprice val HTI_time" style={{display: htiTime}}>
                            -
                          </div>
                          <img onClick={()=>getComparisonsData(allData)} className="refresh_index" style={{cursor: 'pointer', width: '22px'}} src="/img/bullet/refresh.png" />
                          <span>{siteLang == "eng" ? languageText2.liveClickRefreshEn : languageText2.liveClickRefresh}</span>
                          <br />
                          <div className="HSI_time" style={{display: hsiTime}}>
                            {siteLang == "eng" ? languageText2.priceAsAtEn : languageText2.priceAsAt} <span className="ticktime"></span>
                          </div>
                        </td>
                        <td className="uppertbl-col-chglbl">
                          {siteLang == "eng" ? languageText2.priceChangeEn : languageText2.priceChange}
                        </td>
                        <td id="bidchg" className="uppertbl-col-chgval val">0</td>
                      </tr>
                      <tr>
                        <td className="uppertbl-col-pchglbl">
                          %{siteLang == "eng" ? languageText2.priceChangeEn : languageText2.priceChange}
                        </td>
                        <td id="bidpchg" className="uppertbl-col-pchgval val">
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="HTI_time" style={{display: htiTime}}>
                    {siteLang == "eng" ? languageText2.htiTimeEn : languageText2.htiTime} <span className="ticktime"></span>
                  </div>
                  <div className="HSI_time" style={{display: hsiTime, textAlign: 'right'}}>
                    {siteLang == "eng" ? languageText2.hsiTimeEn : languageText2.hsiTime}
                  </div>
                  {loadingIndicator && <div className="loadingindicator" />}
                </div>
                <Chart ricValue1={allData && allData.ric_data.underlying_ric} />
                {loadingIndicator && <div className="loadingindicator" />}
              </div> */}

              <div
                id="dailyhsi"
                className="section qlink-section"
              >
                <div className="page-header">
                  <h1 className="small">
                    {siteLang == "eng"
                      ? languageText.hsiIndexMarketEn
                      : languageText.hsiIndexMarket}
                  </h1>
                </div>
                <div className="news-title" style={{ display: "none" }}>
                  -
                </div>
                <p className="news-summary">-</p>
                <div style={{ textAlign: "right", marginRight: "20px" }}>
                  <Link to="/marketnews/dailyhsi" target="_blank">
                    <img src={Achor} />
                  </Link>
                </div>
              </div>
              <WarrantSearchSideBars />
              <DwTermsSideBars />
              {/* <WarrantCalculatorSideBars /> */}
              <AdvertiseMentSidebar />
            </div>
          </div>
        </div>
      </div>
      <table id="five_day_table_tmpl" style={{ display: "none" }}>
        <thead>
          <tr>
            <th className="th-left" style={{ borderRight: "1px solid #ccc" }}>
              {siteLang == "eng"
                ? languageText.todayTableUnderlyingBidEn
                : languageText.todayTableUnderlyingBid}
            </th>
          </tr>
        </thead>
      </table>
      <div id="text-content" style={{ display: "none" }}>
        <div className="label_underlyingoffer">
          {siteLang == "eng"
            ? languageText.todayTableUnderlyingOfferEn
            : languageText.todayTableUnderlyingOffer}
        </div>
        <table className="table_livematrix_today_empty">
          <tbody>
            <tr>
              <td colSpan="6" className="text-content">
                {siteLang == "eng"
                  ? languageText.livematrixTodayEmptyEn
                  : languageText.livematrixTodayEmpty}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table_livematrix_today_unavailable" >
          <tbody>
            <tr>
              <td colSpan="6" className="text-content">
                {siteLang == "eng"
                  ? languageText.livematrixTodayUnavailableEn
                  : languageText.livematrixTodayUnavailable}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table_livematrix_today_new_unavailable" >
          <tbody>
            <tr>
              <td colSpan="6" className="text-content">
                {siteLang == "eng"
                  ? languageText.livematrixTodayNewUnavailableEn
                  : languageText.livematrixTodayNewUnavailable}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table_livematrix_fiveday_empty">
          <tbody>
            <tr>
              <td colSpan="6" className="text-content">
                {siteLang == "eng"
                  ? languageText.livematrixFivedayEmptyEn
                  : languageText.livematrixFivedayEmpty}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table_livematrix_fiveday_unavailable">
          <tbody>
            <tr>
              <td colSpan="6" className="text-content">
                {siteLang == "eng"
                  ? languageText.livematrixFivedayUnavailableEn
                  : languageText.livematrixFivedayUnavailable}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table_livematrix_none_mq">
          <tbody>
            <tr>
              <td colSpan="6" className="text-content">
                {siteLang == "eng"
                  ? languageText.livematrixTableLivematrixNoneMqEn
                  : languageText.livematrixTableLivematrixNoneMq}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="stickerheader">
        <div className='container-fluid'>
          <div className="row">
            <div className="col-md-8">
              <div className="section">

              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="print_content"></div>
    </>
  );
};

export default WarrantLiveMatrix;
