import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import logo_img from "../../../assets/images/logo.png";
import LangContext from "../context/lang-context";
import {languageText} from "../../../languageText/headerFooter"

const Header = () => {
  const location = useLocation();
  const [visible, setvisible] = useState(false);
  const history = useHistory();
  const langContext = useContext(LangContext);
  const [language, setLanguage] = useState('thai');
  const [tabletMenu, setTabletMenu] = useState('');
  const [toolMenu, setToolMenu] = useState('');
  const [statMenu, setStatMenu] = useState('');
  const [eduMenu, setEduMenu] = useState('');
  const [newsMenu, setNewsMenu] = useState('');
  const [siteLang , setSiteLang] = useState("")
  
  useEffect(() => {
    var path = location.pathname;
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
      setSiteLang('eng')
    }else{
      document.querySelector("body").classList.remove("eng")
      setSiteLang('thai')
    }
    var patharray = path.split("/");
    if (patharray[3] === "investor-academy") {
      setvisible(true);
    } else {
      setvisible(false);
    }
  }, [location]);

  useEffect(() => {
    setTabletMenu("")
    setToolMenu("")
    setStatMenu("")
    setEduMenu("")
    setNewsMenu("")
  }, [location])

  const changeLang = () => {
    if(siteLang == 'eng' || siteLang == '' || siteLang == null){
      langContext.setLanguage('thai')
      setSiteLang('thai')
    }else{
      langContext.setLanguage('eng');
      setSiteLang('eng')
    }
    setTabletMenu("")
    var siteLanguage = (siteLang == 'eng' || siteLang == '' || siteLang == null) ? "thai" : "eng"
    $.cookie('applang', siteLanguage, {expires: 365, path: '/'});
  }

  const showTabletMenu = () => {
    if(tabletMenu == ""){
      setTabletMenu("in")
    }else{
      setTabletMenu("")
    }
  }

  const toolMenuFunction = () => {
    if(toolMenu == ""){
      setToolMenu("in")
      setStatMenu("")
      setEduMenu("")
      setNewsMenu("")
    }else{
      setToolMenu("")
    }
  }

  const statMenuFunction = () => {
    if(statMenu == ""){
      setToolMenu("")
      setStatMenu("in")
      setEduMenu("")
      setNewsMenu("")
    }else{
      setStatMenu("")
    }
  }

  const eduMenuFunction = () => {
    if(eduMenu == ""){
      setToolMenu("")
      setStatMenu("")
      setEduMenu("in")
      setNewsMenu("")
    }else{
      setEduMenu("")
    }
  }

  const newsMenuFunction = () => {
    if(newsMenu == ""){
      setToolMenu("")
      setStatMenu("")
      setEduMenu("")
      setNewsMenu("in")
    }else{
      setNewsMenu("")
    }
  }

  const pageRedirect = (e) => {
    if(e.keyCode == 13) {
      var val = e.target.value
      history.push("/search?key="+val)
    }
  }
  return (
    <>
      <header>
        <nav
          id="topnavbar"
          className="navbar navbar-inverse navbar-fixed-top navbar-larger"
        >
          <div className="container-fluid">
            <div className="navbar-header">
              <Link className="navbar-brand" to="/home">
                <img src={logo_img} alt="Macquarie logo" />
                <span style={{ position: "relative", top: "2px", left: "0px" }}>
                  {(siteLang == "eng") ? " | แมคควอรี DW28" : " | แมคควอรี DW28"}
                </span>
              </Link>
              <button type="button" className="navbar-toggle collapsed visible-sm visible-xs" onClick={showTabletMenu}>
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </button>
              <a className="nav-icon icons-phone-sm visible-xs visible-sm" href="tel:66 2 694 7777">
              {/* <div
                      style={{ display: "none", top: 64, left: "-204px" }}
                      className="dropmenu-content nav-icon-tooltip"
                    >
                      <div className="icon-tooltip-bg" />
                      <div className="icon-tooltip-content">
                        <i>
                          <a  href="tel:66 2 694 7777">+66 2 694 777</a>
                          <br />
                          <a href="mailto:info@malaysiawarrants.com.my">
                            dw28@macquarice.com
                          </a>
                        </i>
                      </div>
                    </div> */}
              </a>
              <a className="nav-icon icons-mail-sm visible-sm" href="mailto:dw28@macquarie.com" />
              <a className="nav-icon icons-fb-sm visible-sm" target="_blank" href="https://www.facebook.com/macquariedw28/" />
              <a className="nav-icon icons-line-sm visible-sm" target="_blank" href="https://line.me/ti/p/%40upt3914e" />
            </div>

            <div id="navbar" className={`navmenu navbar-collapse collapse ${tabletMenu}`}>

              <ul className="nav navbar-nav navbar-right visible-md visible-lg">
                <li>
                  <Link className="dropmenu" to="/home">
                    {(siteLang == "eng") ? languageText.toolHeadingEn : languageText.toolHeading}
                  </Link>
                  <div className="dropmenu-content nav-icon-tooltip center">
                    <div className="icon-tooltip-bg" />
                    <div className="icon-tooltip-content">
                      <div className="dropmenu-row">
                        <Link to="/tools/dwsearch">
                          {(siteLang == "eng") ? languageText.dwSearchEn : languageText.dwSearch}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/tools/livematrix">
                        {(siteLang == "eng") ? languageText.liveMatrixEn : languageText.liveMatrix}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/tools/underlying">
                          {(siteLang == "eng") ? languageText.foreignIndexChartEn : languageText.foreignIndexChart}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/tools/dwterms">
                          {(siteLang == "eng") ? languageText.dwTermsEn : languageText.dwTerms}
                        </Link>
                      </div>
                      {/* <div className="dropmenu-row">
                        <Link to="/tools/dwcalculator">
                        {(siteLang == "eng") ? languageText.dwCalculatorEn : languageText.dwCalculator}
                        </Link>
                      </div> */}
                      <div className="dropmenu-row">
                        <Link to="/tools/dwcompare">
                          {(siteLang == "eng") ? languageText.dwComparisonEn : languageText.dwComparison}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/tools/dwranking">{(siteLang == "eng") ? languageText.dwRankingEn : languageText.dwRanking}</Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link className="dropmenu" to="/home?dwstat">
                    {(siteLang == "eng") ? languageText.statHeadingEn : languageText.statHeading}
                  </Link>
                  <div className="dropmenu-content nav-icon-tooltip center">
                    <div className="icon-tooltip-bg" />
                    <div className="icon-tooltip-content">
                      <div className="dropmenu-row">
                        <Link to="/marketdata/toptradedstock">
                          {(siteLang == "eng") ? languageText.topTradedStockEn : languageText.topTradedStock}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/marketdata/toptradewarrants">
                          {(siteLang == "eng") ? languageText.topTradedWarrantsEn : languageText.topTradedWarrants}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/marketdata/turnoverdist">
                          {(siteLang == "eng") ? languageText.dwTurnoverDistributionEn : languageText.dwTurnoverDistribution}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/marketdata/dwturnover">
                          {(siteLang == "eng") ? languageText.dwMarketTurnoverEn : languageText.dwMarketTurnover}
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link className="dropmenu" to="/home?dwedu">
                    {(siteLang == "eng") ? languageText.eduHeadingEn : languageText.eduHeading}
                  </Link>
                  <div className="dropmenu-content nav-icon-tooltip center">
                    <div className="icon-tooltip-bg" />
                    <div className="icon-tooltip-content">
                    <div className="dropmenu-row">
                        <Link to="/education/landing">{(siteLang == "eng") ? languageText.Boost : languageText.Boost}</Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/education/dwtips">{(siteLang == "eng") ? languageText.dwTipsEn : languageText.dwTips}</Link>
                      </div>
                      {/* <div className="dropmenu-row">
                        <Link to="/education/dwvideo">
                          {(siteLang == "eng") ? languageText.dwVideoEn : languageText.dwVideo}
                        </Link>
                      </div> */}
                      <div className="dropmenu-row">
                        <Link to="/eduhtml" target="_blank">
                          {(siteLang == "eng") ? languageText.investorAcademyEn : languageText.investorAcademy}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/education/indexdw">{(siteLang == "eng") ? languageText.indexDWEn : languageText.indexDW}</Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/education/faq">
                          {(siteLang == "eng") ? languageText.faqEn : languageText.faq}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/education/whydw28">
                          {(siteLang == "eng") ? languageText.whyDW28En : languageText.whyDW28}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/education/seminar">
                          {(siteLang == "eng") ? languageText.seminarEn : languageText.seminar}
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <Link className="dropmenu" to="/home?dwnews">
                    {(siteLang == "eng") ? languageText.newsHeadingEn : languageText.newsHeading}
                  </Link>
                  <div className="dropmenu-content nav-icon-tooltip center">
                    <div className="icon-tooltip-bg" />
                    <div className="icon-tooltip-content">
                      <div className="dropmenu-row dailysinglestock_txt">
                        <Link to="/marketnews/dailysinglestock">
                          {(siteLang == "eng") ? languageText.singleStockUpdateEn : languageText.singleStockUpdate}
                        </Link>
                      </div>
                      {/* <div className="dropmenu-row dailysp500_txt">
                        <Link to="/marketnews/dailysp500">
                          {(siteLang == "eng") ? languageText.dailySpEn : languageText.dailySp}
                        </Link>
                      </div> */}
                      <div className="dropmenu-row">
                        <Link to="/marketnews/dailyhsi">
                          {(siteLang == "eng") ? languageText.dailyHsiEn : languageText.dailyHsi}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/marketnews/todayhighlight">
                          {(siteLang == "eng") ? languageText.todayHighlightEn : languageText.todayHighlight}
                        </Link>
                      </div>
                      {/* <div className="dropmenu-row">
                        <Link to="/marketnews/dailycommentary">
                          {(siteLang == "eng") ? languageText.dailyCommentryEn : languageText.dailyCommentry}
                        </Link>
                      </div> */}
                      {/* <div className="dropmenu-row">
                        <Link to="/marketnews/overnightmarkets">
                          {(siteLang == "eng") ? languageText.overnightMarketEn : languageText.overnightMarket}
                        </Link>
                      </div> */}
                      <div className="dropmenu-row">
                        <Link to="/marketnews/subscribe">
                          {(siteLang == "eng") ? languageText.subscribeEn : languageText.subscribe}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/marketnews/activities">
                          {(siteLang == "eng") ? languageText.activitiesEn : languageText.activities}
                        </Link>
                      </div>
                      <div className="dropmenu-row">
                        <Link to="/marketnews/photogallery">
                          {(siteLang == "eng") ? languageText.photoGalleryEn : languageText.photoGallery}
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="space"
                    style={{ width: "30px", height: "15px" }}
                  />
                </li>

                <li className="li-icon hidden-xs">
                  <a
                    className="nav-icon icons-line"
                    target="_blank"
                    href="https://line.me/ti/p/%40upt3914e"
                  />
                </li>
                <li className="li-icon hidden-xs">
                  <a
                    className="nav-icon icons-fb"
                    target="_blank"
                    href="https://www.facebook.com/macquariedw28/"
                  />
                </li>
                <li className="li-icon hidden-xs">
                  <a
                    className="nav-icon icons-mail"
                    href="mailto:DW28@macquarie.com"
                  />
                </li>
                <li className="li-icon phone hidden-xs">
                  <a className="nav-icon icons-phone" >
                  <div
                      style={{ display: "none", top: '53px', left: "-61px" }}
                      className="dropmenu-content nav-icon-tooltip"
                    >
                      <div className="icon-tooltip-bg" />
                      <div className="icon-tooltip-content">
                        <i>
                          <a  href="tel:66 2 694 7777">+66 2 694 777</a>
                          <br />
                          <a href="mailto:dw28@macquarie.com">
                            dw28@macquarie.com
                          </a>
                        </i>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="li-icon  hidden-xs">
                  <a lag ='eng' className={`dropmenu nav-icon icon-lang-lg ${(siteLang == "eng") ? "en" : "othr"}`}></a>
                  <div className="dropmenu-content nav-icon-tooltip lang">
                    <div className="icon-tooltip-bg" />
                    <div className="icon-tooltip-content">
                      <a lag ='thai'
                        className={`icon-lang-lg en ${(siteLang == "eng") ? "othr" : "en"}`}
                        onClick={changeLang}
                      ></a>
                    </div>
                  </div>
                </li>
              </ul>

              <ul className="navsm nav navbar-nav navbar-right visible-sm">
                <li>
                  <div className="navsm-row navsm-toppanel">
                    <div className="navsm-left">
                      <div id="menusearch" className="navsm-search input-group">
                        <input type="text" className="form-control text-left hsearchbox" placeholder="Search Macquarie" autoComplete="off" onKeyDown={(e) => pageRedirect(e)} />
                      </div>
                    </div>
                    <div className="navsm-right padleft">
                      <button className={`icon-lang-xs othr ${siteLang == "eng" ? "lang_en" : "lang_th"}`} onClick={changeLang} />
                    </div>
                    <div className="navsm-right bordered">
                      <a target="_blank" className="icon-fb-xs" href="https://www.facebook.com/macquariedw28/" />
                    </div>
                    <div className="navsm-right">
                      <a target="_blank" className="icon-line-xs" href="https://line.me/ti/p/%40upt3914e" />
                    </div>
                    <div className="navsm-right">
                      <a className="icon-hotline-xs" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="navsm-col col-sm-3">
                      <div className="navsm-hpanel">
                        <Link to="/home">{(siteLang == "eng") ? languageText.toolHeadingEn : languageText.toolHeading}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/tools/dwsearch">{(siteLang == "eng") ? languageText.dwSearchEn : languageText.dwSearch}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/tools/livematrix">{(siteLang == "eng") ? languageText.liveMatrixEn : languageText.liveMatrix}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/tools/underlying">
                          {(siteLang == "eng") ? languageText.foreignIndexChartEn : languageText.foreignIndexChart}
                        </Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/tools/dwterms">{(siteLang == "eng") ? languageText.dwTermsEn : languageText.dwTerms}</Link>
                      </div>
                      {/* <div className="navsm-panel">
                        <Link to="/tools/dwcalculator">{(siteLang == "eng") ? languageText.dwCalculatorEn : languageText.dwCalculator}</Link>
                      </div> */}
                      <div className="navsm-panel">
                        <Link to="/tools/dwcompare">{(siteLang == "eng") ? languageText.dwComparisonEn : languageText.dwComparison}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/tools/dwranking">{(siteLang == "eng") ? languageText.dwRankingEn : languageText.dwRanking}</Link>
                      </div>                                       
                    </div>
                    <div className="navsm-col col-sm-3">
                      <div className="navsm-hpanel">
                        <Link to="/home?dwstat">{(siteLang == "eng") ? languageText.statHeadingEn : languageText.statHeading}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/marketdata/toptradedstock">{(siteLang == "eng") ? languageText.topTradedStockEn : languageText.topTradedStock}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/marketdata/toptradewarrants">{(siteLang == "eng") ? languageText.topTradedWarrantsEn : languageText.topTradedWarrants}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/marketdata/turnoverdist">{(siteLang == "eng") ? languageText.dwTurnoverDistributionEn : languageText.dwTurnoverDistribution}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/marketdata/dwturnover">{(siteLang == "eng") ? languageText.dwMarketTurnoverEn : languageText.dwMarketTurnover}</Link>
                      </div>
                    </div>
                    <div className="navsm-col col-sm-3">
                      <div className="navsm-hpanel">
                        <Link to="/home?dwedu">{(siteLang == "eng") ? languageText.eduHeadingEn : languageText.eduHeading}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/education/landing">{(siteLang == "eng") ? languageText.Boost : languageText.Boost}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/education/dwtips">{(siteLang == "eng") ? languageText.dwTipsEn : languageText.dwTips}</Link>
                      </div>
                      {/* <div className="navsm-panel">
                        <Link to="/education/dwvideo">{(siteLang == "eng") ? languageText.dwVideoEn : languageText.dwVideo}</Link>
                      </div> */}
                      <div className="navsm-panel">
                        <Link target="_blank" to="/eduhtml">{(siteLang == "eng") ? languageText.investorAcademyEn : languageText.investorAcademy}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/education/indexdw">{(siteLang == "eng") ? languageText.indexDWEn : languageText.indexDW}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/education/faq">{(siteLang == "eng") ? languageText.faqEn : languageText.faq}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/education/whydw28">{(siteLang == "eng") ? languageText.whyDW28En : languageText.whyDW28}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/education/seminar">{(siteLang == "eng") ? languageText.seminarEn : languageText.seminar}</Link>
                      </div>
                    </div>
                    <div className="navsm-col col-sm-3">
                      <div className="navsm-hpanel">
                        <Link to="/home?dwnews">{(siteLang == "eng") ? languageText.newsHeadingEn : languageText.newsHeading}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/marketnews/dailysinglestock">{(siteLang == "eng") ? languageText.singleStockUpdateEn : languageText.singleStockUpdate}</Link>
                      </div>
                      {/* <div className="navsm-panel">
                        <Link to="/marketnews/dailysp500">{(siteLang == "eng") ? languageText.dailySpEn : languageText.dailySp}</Link>
                      </div> */}
                      <div className="navsm-panel">
                        <Link to="/marketnews/dailyhsi">{(siteLang == "eng") ? languageText.dailyHsiEn : languageText.dailyHsi}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/marketnews/todayhighlight">{(siteLang == "eng") ? languageText.todayHighlightEn : languageText.todayHighlight}</Link>
                      </div>
                      {/* <div className="navsm-panel">
                        <Link to="/marketnews/dailycommentary">{(siteLang == "eng") ? languageText.dailyCommentryEn : languageText.dailyCommentry}</Link>
                      </div> */}
                      {/* <div className="navsm-panel">
                        <Link to="/marketnews/overnightmarkets">{(siteLang == "eng") ? languageText.overnightMarketEn : languageText.overnightMarket}</Link>
                      </div> */}
                      <div className="navsm-panel">
                        <Link to="/marketnews/subscribe">{(siteLang == "eng") ? languageText.subscribeEn : languageText.subscribe}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/marketnews/activities">{(siteLang == "eng") ? languageText.activitiesEn : languageText.activities}</Link>
                      </div>
                      <div className="navsm-panel">
                        <Link to="/marketnews/photogallery">{(siteLang == "eng") ? languageText.photoGalleryEn : languageText.photoGallery}</Link>
                      </div>
                    </div>
                  </div>    
                </li>
              </ul>

              <ul className="navmbl nav navbar-nav navbar-right visible-xs">
                <li>
                  <div className="navmbl-row navmbl-toppanel">
                    <div className="navmbl-left">
                      <div id="menusearch" className="navmbl-search input-group">
                        <input type="text" className="form-control text-left hsearchbox" placeholder="Search Macquarie" autoComplete="off" onKeyDown={(e) => pageRedirect(e)} />
                      </div>
                    </div>
                    <div className="navmbl-right padleft">
                      <button className={`icon-lang-xs othr ${siteLang == "eng" ? "lang_en" : "lang_th"}`} onClick={changeLang} />
                    </div>
                    <div className="navmbl-right bordered">
                      <a target="_blank" className="icon-fb-xs" href="https://www.facebook.com/macquariedw28/" />
                    </div>
                    <div className="navmbl-right">
                      <a target="_blank" className="icon-line-xs" href="https://line.me/ti/p/%40upt3914e" />
                    </div>
                    <div className="navmbl-right">
                      <a className="icon-hotline-xs" />
                    </div>
                  </div>
                  <div className="navmbl-row">
                    <div className="navmbl-panel-heading">
                      <div className="navmbl-panel-title" onClick={toolMenuFunction}>
                      {(siteLang == "eng") ? languageText.toolHeadingEn : languageText.toolHeading}
                        <i className="fa fa-angle-down float-right"></i>
                      </div>
                    </div>
                    <div id="mblmenu1" className={`navmbl-panel-collapse collapse ${toolMenu}`}>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/tools/dwsearch">{(siteLang == "eng") ? languageText.dwSearchEn : languageText.dwSearch}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/tools/livematrix">{(siteLang == "eng") ? languageText.liveMatrixEn : languageText.liveMatrix}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/tools/underlying">
                          {(siteLang == "eng") ? languageText.foreignIndexChartEn : languageText.foreignIndexChart}
                        </Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/tools/dwterms">{(siteLang == "eng") ? languageText.dwTermsEn : languageText.dwTerms}</Link>
                      </div>
                      {/* <div className="navmbl-row navmbl-panel">
                        <Link to="/tools/dwcalculator">{(siteLang == "eng") ? languageText.dwCalculatorEn : languageText.dwCalculator}</Link>
                      </div> */}
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/tools/dwcompare">{(siteLang == "eng") ? languageText.dwComparisonEn : languageText.dwComparison}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/tools/dwranking">{(siteLang == "eng") ? languageText.dwRankingEn : languageText.dwRanking}</Link>
                      </div>
                    </div>
                  </div>
                  <div className="navmbl-row">
                    <div className="navmbl-panel-heading">
                      <div className="navmbl-panel-title" onClick={statMenuFunction}>
                        {(siteLang == "eng") ? languageText.statHeadingEn : languageText.statHeading}
                        <i className="fa fa-angle-down float-right"></i>
                      </div>
                    </div>
                    <div id="mblmenu2" className={`navmbl-panel-collapse collapse ${statMenu}`}>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketdata/toptradedstock">{(siteLang == "eng") ? languageText.topTradedStockEn : languageText.topTradedStock}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketdata/toptradewarrants">{(siteLang == "eng") ? languageText.topTradedWarrantsEn : languageText.topTradedWarrants}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketdata/turnoverdist">{(siteLang == "eng") ? languageText.dwTurnoverDistributionEn : languageText.dwTurnoverDistribution}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketdata/dwturnover">{(siteLang == "eng") ? languageText.dwMarketTurnoverEn : languageText.dwMarketTurnover}</Link>
                      </div>
                    </div>
                  </div>    
                  <div className="navmbl-row">
                    <div className="navmbl-panel-heading">
                      <div className="navmbl-panel-title" onClick={eduMenuFunction}>
                        {(siteLang == "eng") ? languageText.eduHeadingEn : languageText.eduHeading}
                        <i className="fa fa-angle-down float-right"></i>
                      </div>
                    </div>
                    <div id="mblmenu3" className={`navmbl-panel-collapse collapse ${eduMenu}`}>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/education/landing">{(siteLang == "eng") ? languageText.Boost : languageText.Boost}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/education/dwtips">{(siteLang == "eng") ? languageText.dwTipsEn : languageText.dwTips}</Link>
                      </div>
                      {/* <div className="navmbl-row navmbl-panel">
                        <Link to="/education/dwvideo">{(siteLang == "eng") ? languageText.dwVideoEn : languageText.dwVideo}</Link>
                      </div> */}
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/eduhtml" target="_blank">{(siteLang == "eng") ? languageText.investorAcademyEn : languageText.investorAcademy}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/education/indexdw">{(siteLang == "eng") ? languageText.indexDWEn : languageText.indexDW}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/education/faq">{(siteLang == "eng") ? languageText.faqEn : languageText.faq}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/education/whydw28">{(siteLang == "eng") ? languageText.whyDW28En : languageText.whyDW28}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/education/seminar">{(siteLang == "eng") ? languageText.seminarEn : languageText.seminar}</Link>
                      </div>
                    </div>
                  </div>    
                  <div className="navmbl-row">
                    <div className="navmbl-panel-heading">
                      <div className="navmbl-panel-title" onClick={newsMenuFunction}>
                        {(siteLang == "eng") ? languageText.newsHeadingEn : languageText.newsHeading}
                        <i className="fa fa-angle-down float-right"></i>
                      </div>
                    </div>
                    <div id="mblmenu4" className={`navmbl-panel-collapse collapse ${newsMenu}`}>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/dailysinglestock">{(siteLang == "eng") ? languageText.singleStockUpdateEn : languageText.singleStockUpdate}</Link>
                      </div>
                      {/* <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/dailysp500">{(siteLang == "eng") ? languageText.dailySpEn : languageText.dailySp}</Link>
                      </div> */}
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/dailyhsi">{(siteLang == "eng") ? languageText.dailyHsiEn : languageText.dailyHsi}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/todayhighlight">{(siteLang == "eng") ? languageText.todayHighlightEn : languageText.todayHighlight}</Link>
                      </div>
                      {/* <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/dailycommentary">{(siteLang == "eng") ? languageText.dailyCommentryEn : languageText.dailyCommentry}</Link>
                      </div> */}
                      {/* <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/overnightmarkets">{(siteLang == "eng") ? languageText.overnightMarketEn : languageText.overnightMarket}</Link>
                      </div> */}
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/subscribe">{(siteLang == "eng") ? languageText.subscribeEn : languageText.subscribe}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/activities">{(siteLang == "eng") ? languageText.activitiesEn : languageText.activities}</Link>
                      </div>
                      <div className="navmbl-row navmbl-panel">
                        <Link to="/marketnews/photogallery">{(siteLang == "eng") ? languageText.photoGalleryEn : languageText.photoGallery}</Link>
                      </div>
                    </div>
                  </div>    
                  <div className="navmbl-row">
                    <div className="navmbl-banner">
                      <Link to="/education/whydw28"><img src="/img/home/u412_a.png" alt="Why DW 28" /></Link>
                    </div>
                  </div>  
                </li>
              </ul>

            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
